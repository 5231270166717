import React, { lazy } from 'react';
import { Droplet } from 'react-feather';
import { RouteInfoType, RouteModuleType, RouteType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import { AccountRole } from '../../services/accounts/types';

const LeakDetectionPage = lazy(() => import('./pages/leakDetection/index'));
const FireDetectionPage = lazy(() => import('./pages/fireDetection/index'));
const SecurityPage = lazy(() => import('./pages/security/index'));

const emergencyRoutes = {
    id: 'manager',
    path: '/emergency',
    name: 'fd',
    icon: <Droplet />,
    hide: true,
    component: <LayoutWithMenu />,
    routes: [
        {
            path: '/emergency/3434',
            header: 'Аварийные ситуации',
            type: RouteType.section,
            childrenModule: [
                RouteModuleType.showSecurityDetection,
                RouteModuleType.showFireDetection,
                RouteModuleType.showLeakDetection,
            ],
        },
        {
            path: '/emergency/leakDetector',
            icon: <SidebarIcon type={SidebarIconType.leak} />,
            name: 'Протечки',
            component: <LeakDetectionPage />,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.showLeakDetection,
        },
        {
            path: '/emergency/fireDetector',
            icon: <SidebarIcon type={SidebarIconType.fire} />,
            name: 'Пожарная сигнализация',
            component: <FireDetectionPage />,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.showFireDetection,
        },
        {
            path: '/emergency/security',
            icon: <SidebarIcon type={SidebarIconType.security} />,
            name: 'Охрана',
            component: <SecurityPage />,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.showSecurityDetection,
            hide: false,
        },
    ],
} as RouteInfoType;

export default emergencyRoutes;
