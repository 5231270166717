import React from 'react';
import { Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';

export const SnackbarComponent = observer(() => {
    const { generalStore } = useStores();

    return (
        <Snackbar
            open={generalStore.snackbar.isOpen}
            onClose={() => generalStore.closeSnackbar()}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            message={
                <span style={{ whiteSpace: 'pre-line' }} id='message-id'>
                    {generalStore.snackbar.title}
                </span>
            }
        />
    );
});
