import { action, makeAutoObservable } from 'mobx';
import { ChatMessageAttachmentClient } from '../../../../services/chat/models';
import { AttachmentClient } from '../../../../services/objectStorage/models';

export class FullScreenModalStore {
    isOpen = false;
    currentAttachments: ChatMessageAttachmentClient[] | AttachmentClient[] = [];
    currentAttachmentLink: string;
    currentAttachmentName: string;
    formatAttachment: string;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setFormatAttachment(format: string) {
        this.formatAttachment = format;
    }

    @action
    setCurrentAttachmentLinkAndName(link: string, name: string) {
        this.currentAttachmentLink = link;
        this.currentAttachmentName = name;
    }

    @action
    openFullScreenModal(
        link: string,
        attachments: ChatMessageAttachmentClient[] | AttachmentClient[],
        format: string,
        name: string,
    ) {
        this.isOpen = true;
        this.setFormatAttachment(format);
        this.currentAttachmentLink = link;
        this.currentAttachmentName = name;
        this.currentAttachments = attachments;
    }

    @action
    closeFullScreenModal() {
        this.isOpen = false;
        this.currentAttachmentLink = undefined;
        this.currentAttachmentName = undefined;
        this.currentAttachments = undefined;
    }
}

const fullScreenModalStore = new FullScreenModalStore();

export default fullScreenModalStore;
