import {
    ConnectionLineData,
    FlatData,
    HouseData,
    HubData,
    NotificationSetting,
    PollingConfig,
    PollingLogDetails,
    SensorData,
    SensorMeasurement,
    SensorMeasurementData,
    SensorPlaceholderData,
    SystemObject,
} from './models';
import { UnifiedMeasurementType } from '../flatView/models';
import { ActiveAlert } from '../alerts/models';

export enum SystemObjectType {
    House = 'House',
    Flat = 'Flat',
    SensorPlaceholder = 'SensorPlaceholder',
    Sensor = 'Sensor',
    Hub = 'Hub',
    ConnectionLine = 'ConnectionLine',
}

export interface SystemObjectHouse extends SystemObject {
    type: SystemObjectType.House;
    data: HouseData;
}

export interface SystemObjectFlat extends SystemObject {
    type: SystemObjectType.Flat;
    data: FlatData;
}

export interface SystemObjectSensorPlaceholder extends SystemObject {
    type: SystemObjectType.SensorPlaceholder;
    data: SensorPlaceholderData;
}

export interface SystemObjectSensor extends SystemObject {
    type: SystemObjectType.Sensor;
    data: SensorData;
    activeAlerts: { [key: string]: ActiveAlert | null };
}

export interface SystemObjectHub extends SystemObject {
    type: SystemObjectType.Hub;
    data: HubData;
    activeAlerts: { [key: string]: ActiveAlert | null };
}

export interface SystemObjectConnectionLine extends SystemObject {
    type: SystemObjectType.ConnectionLine;
    data: ConnectionLineData;
    activeAlerts: { [key: string]: ActiveAlert | null };
}

export type SystemObjectT =
    | SystemObjectSensor
    | SystemObjectSensorPlaceholder
    | SystemObjectFlat
    | SystemObjectHouse
    | SystemObjectHub
    | SystemObjectConnectionLine;

export enum HubModelIds {
    WiseCityV1 = 'WiseCityHubV1',
    ZigbeeCoordinator = 'ZigbeeCoordinator',
    WirenboardV6 = 'WirenBoardV6',
    PulsarRS485ToEthernet = 'PulsarRS485ToEthernet',
    VegaServer = 'IOTVegaServer',
    PulsarIoTHub = 'Pulsar_IoT_Hub',
    VegaSI22Modem = 'VegaSI22Modem',
    VegaSI13Modem = 'VegaSI13Modem',
    VegaSI11Modem = 'VegaSI11Modem',
    NovouchetMLRWController = 'Novouchet_M_LRW_Controller',
    NovouchetMLRWControllerWithExternalAntenna = 'Novouchet_M_LRW_External_Antenna',
    NovouchetMLRWImpulseCounter = 'Novouchet_M_LRW_ImpulseCounter',
    PulsarIoTHubCluster = 'Pulsar_IoT_Hub_Cluster',
    RubezhProtocolConverter = 'RubezhProtocolConverter',
    BolidProtocolConverter = 'BolidProtocolConverter',
    UnifiedHub = 'UnifiedHub',
    PulsarGsmModem = 'PulsarGsmModem',
    UM31SmartHub = 'UM31SmartHub',
    EntekUSPD = 'EntekUSPD',
    JethomeD1 = 'JethomeD1',
}

export enum Revision {
    REV_1 = 'Ревизия 1',
    REV_2 = 'Ревизия 2',
}

export interface PulsarRS485ToEthernetHubSettings {
    hubModelId: HubModelIds.PulsarRS485ToEthernet;
    host: string;
}

export interface HubSettingsZigbeeCoordinator {
    hubModelId: HubModelIds.ZigbeeCoordinator;
}

export interface HubSettingsUnifiedHub {
    hubModelId: HubModelIds.UnifiedHub;
}

export interface WiseCityV1HubSettings {
    hubModelId: HubModelIds.WiseCityV1;
    hardwareId: string;
}

export enum VegaProtocol {
    wss = 'wss',
    ws = 'ws',
}

export interface HubSettingsPulsarIoTHubCluster {
    hubModelId: HubModelIds.PulsarIoTHubCluster;
    childConnectionLinesSystemObjectIds: number[];
}

interface HubSettingsIOTVegaServer {
    hubModelId: HubModelIds.VegaServer;
    host: string;
    login: string;
    password: string;
    port: string;
    protocol: VegaProtocol;
}

interface HubSettingsPulsarIOTHub {
    hubModelId: HubModelIds.PulsarIoTHub;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
    address: string;
}

export interface HubSettingsVegaSI22Modem {
    hubModelId: HubModelIds.VegaSI22Modem;
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
}

export interface HubSettingsVegaSI13Modem {
    hubModelId: HubModelIds.VegaSI13Modem;
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
}

export interface HubSettingsVegaSI11Modem {
    hubModelId: HubModelIds.VegaSI11Modem;
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
    revision: Revision;
}

export interface HubSettingsNovouchetMLRWController {
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
    hubModelId: HubModelIds.NovouchetMLRWController;
}

export interface HubSettingsNovouchetMLRWControllerWithExternalAntenna {
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
    hubModelId: HubModelIds.NovouchetMLRWControllerWithExternalAntenna;
}

export interface HubSettingsNovouchetMLRWImpulseCounter {
    childConnectionLineSystemObjectId: number;
    childHubSystemObjectId: number;
    devEui: string;
    hubModelId: HubModelIds.NovouchetMLRWImpulseCounter;
}

export interface HubSettingsRubezhProtocolConverter {
    hubModelId: HubModelIds.RubezhProtocolConverter;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
    address: string;
    executiveDevicesQuantity: number | null;
    zonesQuantity: number | null;
    appliancesQuantity: number | null;
    serialSettings: string | null;
}

export interface HubSettingsBolidProtocolConverter {
    hubModelId: HubModelIds.BolidProtocolConverter;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
    address: string;
}

export interface HubSettingsPulsarGsmModem {
    hubModelId: HubModelIds.PulsarGsmModem;
    callingTimeout: number;
    dialingAttempts: number;
    lineHoldTimeout: number;
    phoneNumber: string;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
    address: string;
}

export interface HubSettingsUM31SmartHub {
    hubModelId: HubModelIds.UM31SmartHub;
    password: string;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
}

export interface HubSettingsEntekUSPD {
    hubModelId: HubModelIds.EntekUSPD;
    childHubSystemObjectId: number;
    childConnectionLineSystemObjectId: number;
    password: string;
    address: number;
}

export interface HubSettingsWirenboardV6 {
    hubModelId: HubModelIds.WirenboardV6;
    hardwareId: string;
}

export interface HubSettingsJethomeD1 {
    hubModelId: HubModelIds.JethomeD1;
}

export type HubSettings =
    | PulsarRS485ToEthernetHubSettings
    | WiseCityV1HubSettings
    | HubSettingsIOTVegaServer
    | HubSettingsPulsarIOTHub
    | HubSettingsVegaSI13Modem
    | HubSettingsVegaSI11Modem
    | HubSettingsNovouchetMLRWController
    | HubSettingsNovouchetMLRWControllerWithExternalAntenna
    | HubSettingsNovouchetMLRWImpulseCounter
    | HubSettingsPulsarIoTHubCluster
    | HubSettingsRubezhProtocolConverter
    | HubSettingsBolidProtocolConverter
    | HubSettingsZigbeeCoordinator
    | HubSettingsUnifiedHub
    | HubSettingsPulsarGsmModem
    | HubSettingsVegaSI22Modem
    | HubSettingsUM31SmartHub
    | HubSettingsEntekUSPD
    | HubSettingsWirenboardV6
    | HubSettingsJethomeD1;

export enum ConnectionLineModelId {
    RS485ToEthernet = 'RS485ToEthernet',
    UARTToWiseCityHubV1 = 'UARTToWiseCityHubV1',
    IOTVegaServerLoraWAN = 'IOTVegaServerLoraWAN',
    PulsarIOTConnectionLine = 'Pulsar_IoT_Hub',
    VegaSI11Modem = 'VegaSI11Modem',
    VegaSI13Modem = 'VegaSI13Modem',
    VegaSI22Modem = 'VegaSI22Modem',
    NovouchetMLRWController = 'Novouchet_M_LRW_Controller',
    NovouchetMLRWImpulseCounterChannel = 'Novouchet_M_LRW_ImpulseCounter_Channel',
    NovouchetMLRWExternalAntenna = 'Novouchet_M_LRW_External_Antenna',
    PulsarIoTHubCluster = 'Pulsar_IoT_Hub_Cluster',
    RubezhProtocolConverter = 'RubezhProtocolConverter',
    BolidProtocolConverter = 'BolidProtocolConverter',
    ZigbeeCoordinator = 'ZigbeeCoordinator',
    UnifiedConnectionLine = 'UnifiedConnectionLine',
    PulsarGsmModem = 'PulsarGsmModem',
    UM31Smart = 'UM31Smart',
    EntekUSPD = 'EntekUSPD',
    WirenboardV6MQTT = 'WirenboardV6',
    WirenboardRS485 = 'WirenBoardRS485',
    JethomeRS485 = 'JethomeRS485',
}

export interface ConnectionLinePulsarIoTHubCluster {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.PulsarIoTHubCluster;
}

export interface ConnectionLineZigbeeCoordinator {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.ZigbeeCoordinator;
}

export interface ConnectionLineUnifiedLine {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.UnifiedConnectionLine;
}

export interface ConnectionLineVegaSI13Modem {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.VegaSI13Modem;
}

export interface ConnectionLineVegaSI22Modem {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.VegaSI22Modem;
}

export interface ConnectionLineVegaSI11Modem {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.VegaSI11Modem;
}

export interface ConnectionLinePulsarRS485ToEthernet {
    host: string;
    hubSystemObjectId: number;
    tcpPort: number;
    connectionLineModelId: ConnectionLineModelId.RS485ToEthernet;
    softwareParityIn7bitMode: boolean;
}

export interface ConnectionLineWiseCityHubV1ToUART {
    channelId: number;
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.UARTToWiseCityHubV1;
    retryStep: number;
    maxRetries: number;
    timeout: number;
}

export interface ConnectionLineIOTVegaServerLoraWAN {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.IOTVegaServerLoraWAN;
}

export interface ConnectionLinePulsarIOTHub {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.PulsarIOTConnectionLine;
}

export interface ConnectionLineNovouchetMLRWController {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.NovouchetMLRWController;
}

export interface ConnectionLineNovouchetMLRWControllerExternalAntenna {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.NovouchetMLRWExternalAntenna;
}

export interface ConnectionLineNovouchetMLRWImpulseCounterChannel {
    channelId: number;
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.NovouchetMLRWImpulseCounterChannel;
}

export interface ConnectionLineRubezhProtocolConverter {
    channelId: number;
    connectionLineModelId: ConnectionLineModelId.RubezhProtocolConverter;
    hubSystemObjectId: number;
    type: string;
}

export interface ConnectionLineBolidProtocolConverter {
    channelId: number;
    connectionLineModelId: ConnectionLineModelId.BolidProtocolConverter;
    hubSystemObjectId: number;
    type: string;
}

export interface ConnectionLinePulsarGsmModem {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.PulsarGsmModem;
}

export interface ConnectionLineUM31Smart {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.UM31Smart;
}

export interface ConnectionLineWirenboardMQTT {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.WirenboardV6MQTT;
}

export interface ConnectionLineWirenboardRS485 {
    hubSystemObjectId: number;
    connectionLineModelId: ConnectionLineModelId.WirenboardRS485;
    channel: number;
}

export interface ConnectionLineJethomeRS485 {
    connectionLineModelId: ConnectionLineModelId.JethomeRS485;
    channelId: number;
    retryStep: number;
    maxRetries: number;
    timeout: number;
}

export type ConnectionLineSettings =
    | ConnectionLinePulsarRS485ToEthernet
    | ConnectionLineWiseCityHubV1ToUART
    | ConnectionLineIOTVegaServerLoraWAN
    | ConnectionLinePulsarIOTHub
    | ConnectionLineVegaSI13Modem
    | ConnectionLineVegaSI11Modem
    | ConnectionLineNovouchetMLRWController
    | ConnectionLineNovouchetMLRWImpulseCounterChannel
    | ConnectionLinePulsarIoTHubCluster
    | ConnectionLineRubezhProtocolConverter
    | ConnectionLineBolidProtocolConverter
    | ConnectionLineZigbeeCoordinator
    | ConnectionLineUnifiedLine
    | ConnectionLineNovouchetMLRWControllerExternalAntenna
    | ConnectionLinePulsarGsmModem
    | ConnectionLineVegaSI22Modem
    | ConnectionLineUM31Smart
    | ConnectionLineWirenboardRS485
    | ConnectionLineWirenboardMQTT
    | ConnectionLineJethomeRS485;

export type SensorPacketTitle =
    | 'HeatMeterCompactPulsarV4'
    | 'HeatMeterPulsarEco'
    | 'ElectricityEnergomeraCE102M'
    | 'ElectricityMercury200_02'
    | 'ElectricityMercury206'
    | 'ElectricityMercury230'
    | 'ElectricityMercury234'
    | 'ElectricityMercury236'
    | 'Milur107'
    | 'Milur307Energy'
    | 'Milur307EnergyByDirections'
    | 'ImpulseCounterPulsar'
    | 'VegaSI11_CurrentValues'
    | 'VegaSI11_Alarm'
    | 'VegaSI13_RS485_CurrentValues'
    | 'VegaSI13_RS485_Alarm'
    | 'VegaTP11_4_20_mA_CurrentValues'
    | 'VegaSmartMC0101_CurrentValues'
    | 'ImpulseCounterIoTWaterPulsarV1_1'
    | 'WiseCityLeakControllerV1_CurrentValues'
    | 'HeatMeterAvektra'
    | 'ImpulseCounterWaterPulsarRS485'
    | 'VegaSI13_TariffPacket'
    | 'VegaSI13_ImpulsePacket'
    | 'VegaSI11_ImpulsePacket'
    | 'OwenControllerPR100_230_0804'
    | 'Novouchet_ConnectedSensor_Electricity'
    | 'Novouchet_ConnectedSensor_Heat'
    | 'Novouchet_ConnectedSensor_ImpulseMode1'
    | 'RubezhSecurityZone'
    | 'RubezhFireZone'
    | 'Rubezh_ExecutiveDevice_IVEPR'
    | 'Rubezh_ExecutiveDevice_MPT_1'
    | 'Rubezh_ExecutiveDevice_MDU_1'
    | 'RubezhAppliance'
    | 'RubezhExecutiveDevice';

export interface FlatClient {
    chatId: number | null;
    data: FlatData;
    id: number;
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    rootPath: number[];
    type: SystemObjectType.Flat;
}

export interface SensorClient {
    data: SensorData;
    id: number;
    lastMeasurementArrivedToBackendAt: Date | null;
    lastMeasurementArrivedToHubAt: Date | null;
    lastMeasurementMeasuredAt: Date | null;
    lastSensorMeasurementMap: { [key: string]: SensorMeasurement };
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    rootPath: number[];
    type: SystemObjectType.Sensor;
    unifiedLastSensorMeasurementMap: { [key: string]: UnifiedMeasurementType };
}

export interface HouseClient {
    data: HouseData;
    id: number;
    manualSendingNotificationsEnabled: boolean;
    notificationSettings: NotificationSetting[];
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    pollingConfig: PollingConfig;
    pollingLogDetails: PollingLogDetails;
    rootPath: number[];
    type: SystemObjectType.House;
}

export interface SensorPlaceholderClient {
    data: SensorPlaceholderData;
    id: number;
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    rootPath: number[];
    type: SystemObjectType.SensorPlaceholder;
}

export interface ConnectionLineClient {
    data: ConnectionLineData;
    id: number;
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    rootPath: number[];
    type: SystemObjectType.ConnectionLine;
}

export interface HubClient {
    data: HubData;
    id: number;
    orgBranchId: number;
    organizationId: number;
    ownerAccountId: number;
    parentId: number | null;
    rootPath: number[];
    type: SystemObjectType.Hub;
}

export type SystemObjectClient =
    | FlatClient
    | HouseClient
    | SensorClient
    | SensorPlaceholderClient
    | ConnectionLineClient
    | HubClient;

export type SensorModelName =
    | 'HeatMeterCompactPulsarV4'
    | 'HeatMeterPulsarEco'
    | 'ElectricityEnergomeraCE102M'
    | 'ElectricityMercury200_02'
    | 'ElectricityMercury206'
    | 'ElectricityMercury230'
    | 'ElectricityMercury234'
    | 'ElectricityMercury236'
    | 'Milur107'
    | 'Milur307'
    | 'ImpulseCounterPulsar'
    | 'VegaSI11'
    | 'VegaSI13_RS485'
    | 'VegaTP11_4_20_mA'
    | 'VegaSmartMC0101'
    | 'ImpulseCounterIoTWaterPulsarV1_1'
    | 'WiseCityLeakControllerV1'
    | 'HeatMeterAvektra'
    | 'ImpulseCounterWaterPulsarRS485'
    | 'VegaSI13_ConnectedSensor_Impulse'
    | 'VegaSI13_ConnectedSensor_Tariff'
    | 'VegaSI11_ConnectedSensor_Impulse'
    | 'OwenControllerPR100_230_0804'
    | 'Novouchet_ConnectedSensor_Electricity'
    | 'Novouchet_ConnectedSensor_Heat'
    | 'Novouchet_ConnectedSensor_ImpulseMode1'
    | 'Pulsar_1F4T_Electricity'
    | 'RubezhSecurityZone'
    | 'RubezhFireZone'
    | 'Rubezh_ExecutiveDevice_IVEPR'
    | 'Rubezh_ExecutiveDevice_MPT_1'
    | 'Rubezh_ExecutiveDevice_MDU_1'
    | 'RubezhAppliance'
    | 'RubezhExecutiveDevice'
    | 'Termotronik_TV7'
    | 'Aquara_MCCGQ11LM'
    | 'Aquara_RTCGQ11LM'
    | 'Aquara_SP_EUC01'
    | 'Aquara_WRS_R02'
    | 'Aquara_SJCGQ11LM'
    | 'Aquara_VOCKQJK11LM'
    | 'Aquara_WSDCGQ11LM'
    | 'Tuya_TS0205'
    | 'Tuya_TS0601_Thermostat'
    | 'WB_MSW_V3'
    | 'WB_MDM3'
    | 'WBIO_DI_WD_14'
    | 'WBIO_DO_R10R_4'
    | 'Um31Smart'
    | 'Entek_Mercury230'
    | 'Entek_Mercury200'
    | 'VzletTCP026'
    | 'VzletTCP043';

export interface SMDWiseCityLeakControllerV1 extends SensorMeasurementData {
    deviceName: string;
    driveOpeningTimeout: number;
    leakSensorResistance: number;
    measurementSourceId: string;
    parsedDrivesErrors: ParsedDriversStateError;
    parsedDrivesState: ParsedDriversState;
    parsedSensorsFixedState: ParsedSensorsState;
    parsedSensorsState: ParsedSensorsState;
    parsedWorkingMode: ParsedWorkingMode;
    securitySensorResistance: number;
    sensorAddress: string;
    sensorG1Resistance: number;
    sensorG2Resistance: number;
    sensorG3Resistance: number;
    sensorG4Resistance: number;
    softwareVersion: string;
    systemObjectId: number;
    voltage: number;
    voltageThreshold: number;
}

export type DriverState = 'Opened' | 'Closed' | 'Unknown';

export interface ParsedDriversState {
    chDriveG1: DriverState;
    chDriveG2: DriverState;
    chDriveG3: DriverState;
    chDriveG4: DriverState;
    clDriveG1: DriverState;
    clDriveG2: DriverState;
    clDriveG3: DriverState;
    clDriveG4: DriverState;
}

export interface ParsedWorkingMode {
    operatingMode: string;
    powerMode: string;
}

export interface ParsedSensorsState {
    leakSensor: number;
    securitySensor: number;
    sensorAlarmResistance: number;
    sensorG1: number;
    sensorG2: number;
    sensorG3: number;
    sensorG4: number;
}

export type DriverStateError =
    | 'OPEN_FAILED'
    | 'CLOSE_FAILED'
    | 'BOTH_FAILED'
    | 'Normal';

export interface ParsedDriversStateError {
    chDriveG1: DriverStateError;
    chDriveG2: DriverStateError;
    chDriveG3: DriverStateError;
    chDriveG4: DriverStateError;
    clDriveG1: DriverStateError;
    clDriveG2: DriverStateError;
    clDriveG3: DriverStateError;
    clDriveG4: DriverStateError;
}

export type SensorStatus = 'Active' | 'Deactivated';

export type LeakFlatStatus = 'Active' | 'Deactivated' | 'PartDeactivated';

export type LeakFlatAlertStatus =
    | 'NoAlerts'
    | 'AnyAlert'
    | 'LostConnection'
    | 'SecurityAlert'
    | 'LeakageAlert'
    | 'LeakageAlertAndLostConnection'
    | 'LeakageAlertAndSecurityAlert'
    | 'LostConnectionAndSecurityAlert'
    | 'AllAlerts';

export type FireFlatAlertStatus =
    | 'NoAlerts'
    | 'AnyAlert'
    | 'LostConnection'
    | 'FireAlert'
    | 'AllAlerts';

export const SensorStatusEnum: { [key in SensorStatus]?: string } = {
    Active: 'Активно',
    Deactivated: 'Выключено',
};
