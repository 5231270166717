export enum CrmAPI {
    SupportRequestsCreateSupportRequest = 'SupportRequests.CreateSupportRequest',
    SupportRequestsGetSupportRequest = 'SupportRequests.GetSupportRequest',
    SupportRequestsGetSupportRequestByViewableFlats = 'SupportRequests.GetSupportRequestByViewableFlats',
    SupportRequestsGetSupportRequestList = 'SupportRequests.GetSupportRequestList',
    SupportRequestsPushSupportRequestMessage = 'SupportRequests.PushSupportRequestMessage',
    SupportRequestsUpdateFeedback = 'SupportRequests.UpdateFeedback',
    SupportRequestsUpdateSupportRequestStatus = 'SupportRequests.UpdateSupportRequestStatus',
    SupportRequestsUpdateSupportRequest = 'SupportRequests.UpdateSupportRequest',
    SupportRequestLogsGetLogs = 'SupportRequestLogs.GetLogs',
    SupportRequestsGetByFlatId = 'SupportRequests.GetByFlatId',
    SupportRequestsGetSupportRequestsStatistics = 'SupportRequests.GetSupportRequestsStatistics',
    SupportRequestsDeleteSupportRequestSystemAttachment = 'SupportRequests.DeleteSupportRequestSystemAttachment',
    SupportRequestsCreateFromPreSupportRequest = 'SupportRequests.CreateFromPreSupportRequest',
    SupportRequestsRemoveLinkedPreSupportRequest = 'SupportRequests.RemoveLinkedPreSupportRequest',
}
