import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { ChatMessageAttachmentClient } from '../../../services/chat/models';
import { FileTypes } from '../../../services/objectStorage/libs';
import FullScreenModalStoreContext from '../fullScreenModalComponent/store/context';
import Loader from '../Loader';
import pdfIcon from '../../../assets/docs/pdf.png';
import docxIcon from '../../../assets/docs/word.png';
import xlsxIcon from '../../../assets/docs/excel.png';
import ppIcon from '../../../assets/docs/pptx.png';
import { isHeic } from '../../utils/attachmentsViewUtils';
import HeicConverter from '../HeicConverter/heicConverter';

interface ChatMessageAttachmentProps {
    item: ChatMessageAttachmentClient;
    allAttachments: ChatMessageAttachmentClient[];
}

const ChatMessageAttachment = (props: ChatMessageAttachmentProps) => {
    const [load, setLoad] = useState(false);
    const fullScreenModalStore = useContext(FullScreenModalStoreContext);
    const split = props.item.fileName.toLowerCase().split('.');
    const formatName = split[split.length - 1];
    const docFormats = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const filterAttachments =
        props.allAttachments.length &&
        props.allAttachments.filter((i) => {
            const filterSplit = i.fileName
                ? i.fileName.toLowerCase().split('.')
                : i.filename.toLowerCase().split('.');
            const filterName = filterSplit[filterSplit.length - 1];
            return !docFormats.includes(filterName);
        });

    const render = () => {
        switch (FileTypes[formatName]) {
            case 'pdf':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    >
                        <img
                            src={pdfIcon}
                            style={{
                                maxWidth: 30,
                                maxHeight: 30,
                            }}
                            onLoad={() => setLoad(true)}
                        />
                        <span
                            style={{
                                marginLeft: 5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#2A73FF',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                window.open(props.item.downloadLink, '_blank')
                            }
                        >
                            {props.item.fileName}
                        </span>
                    </div>
                );
            case 'pp':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    >
                        <img
                            src={ppIcon}
                            style={{
                                maxWidth: 30,
                                maxHeight: 30,
                            }}
                            onLoad={() => setLoad(true)}
                        />
                        <span
                            style={{
                                marginLeft: 5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#2A73FF',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                window.open(props.item.downloadLink, '_blank')
                            }
                        >
                            {props.item.fileName}
                        </span>
                    </div>
                );
            case 'word':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    >
                        <img
                            src={docxIcon}
                            style={{
                                maxWidth: 30,
                                maxHeight: 30,
                            }}
                            onLoad={() => setLoad(true)}
                        />
                        <span
                            style={{
                                marginLeft: 5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#2A73FF',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                window.open(props.item.downloadLink, '_blank')
                            }
                        >
                            {props.item.fileName}
                        </span>
                    </div>
                );
            case 'excel':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    >
                        <img
                            src={xlsxIcon}
                            style={{
                                maxWidth: 30,
                                maxHeight: 30,
                            }}
                            onLoad={() => setLoad(true)}
                        />
                        <span
                            style={{
                                marginLeft: 5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#2A73FF',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                window.open(props.item.downloadLink, '_blank')
                            }
                        >
                            {props.item.fileName}
                        </span>
                    </div>
                );
            case 'image':
                if (isHeic(props.item.fileName)) {
                    return (
                        <HeicConverter
                            url={props.item.downloadLink}
                            name={
                                props.item.fileName
                                    ? props.item.fileName
                                    : props.item.filename
                            }
                            attachments={props.allAttachments}
                            style={{
                                maxWidth: 120,
                                height: 80,
                                borderRadius: 5,
                                marginRight: 5,
                                cursor: 'pointer',
                                marginTop: 5,
                                objectFit: 'contain',
                            }}
                            setLoad={() => setLoad(true)}
                        />
                    );
                } else {
                    return (
                        <img
                            style={{
                                maxWidth: 120,
                                height: 80,
                                borderRadius: 5,
                                marginRight: 5,
                                cursor: 'pointer',
                                marginTop: 5,
                                objectFit: 'contain',
                            }}
                            alt={''}
                            src={props.item.downloadLink}
                            onClick={() =>
                                fullScreenModalStore.openFullScreenModal(
                                    props.item.downloadLink,
                                    filterAttachments,
                                    'image',
                                    props.item.fileName,
                                )
                            }
                            onLoad={() => setLoad(true)}
                        />
                    );
                }
            case 'video':
                return (
                    <video
                        src={props.item.downloadLink}
                        style={{
                            maxWidth: 120,
                            height: load ? 80 : 0,
                            borderRadius: 5,
                            marginRight: 5,
                            cursor: 'pointer',
                            marginTop: 5,
                            objectFit: 'contain',
                        }}
                        autoPlay={false}
                        muted={true}
                        playsInline={true}
                        onClick={() =>
                            fullScreenModalStore.openFullScreenModal(
                                props.item.downloadLink,
                                filterAttachments,
                                'video',
                                props.item.fileName,
                            )
                        }
                        onLoadedMetadata={() => setLoad(true)}
                        id={`videoFile + ${props.item.downloadLink}`}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div
            style={{
                width:
                    FileTypes[formatName] !== 'video' &&
                    FileTypes[formatName] !== 'image' &&
                    '100%',
            }}
        >
            {!load ? (
                formatName !== 'heic' ? (
                    <div
                        style={{
                            maxWidth: 120,
                            height: 80,
                            borderRadius: 5,
                            marginTop: 5,
                            marginRight: 5,
                            backgroundColor: '#F1F1F1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            objectFit: 'cover',
                        }}
                    >
                        {<Loader />}
                    </div>
                ) : null
            ) : null}
            {render()}
        </div>
    );
};

export default observer(ChatMessageAttachment);
