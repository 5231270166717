import {
    PreSupportRequestGetPreSupportRequestsRequest,
    PreSupportRequestGetPreSupportRequestsResponse,
    PreSupportRequestGetTelephonyRecordingsRequest,
    PreSupportRequestGetTelephonyRecordingsResponse,
    PreSupportRequestUpdateCallerAccountRequest,
    PreSupportRequestUpdateCallerAccountResponse,
    TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
    TelephonyProviderCreateMangoOfficeTelephonyProviderResponse,
    TelephonyProviderGetTelephonyProvidersRequest,
    TelephonyProviderGetTelephonyProvidersResponse,
    TelephonyProviderRemoveRequest,
    TelephonyProviderRemoveResponse,
    TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
    TelephonyProviderUpdateMangoOfficeTelephonyProviderResponse,
} from './requestsResponses';
import { execute } from '../../apiCommandsExecutor';
import { TelephonyApi } from './methods';

export const createTelephonyProvider = async (
    params: TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
) => {
    const res = await execute<
        TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
        TelephonyProviderCreateMangoOfficeTelephonyProviderResponse
    >(TelephonyApi.TelephonyProviderCreateMangoOfficeTelephonyProvider, {
        ...params,
    });
    return res.data;
};

export const updateTelephonyProvider = async (
    params: TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
) => {
    const res = await execute<
        TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
        TelephonyProviderUpdateMangoOfficeTelephonyProviderResponse
    >(TelephonyApi.TelephonyProviderUpdateMangoOfficeTelephonyProvider, {
        ...params,
    });
    return res.data;
};

export const getTelephonyProviders = async (
    params: TelephonyProviderGetTelephonyProvidersRequest,
) => {
    const res = await execute<
        TelephonyProviderGetTelephonyProvidersRequest,
        TelephonyProviderGetTelephonyProvidersResponse
    >(TelephonyApi.TelephonyProviderGetTelephonyProviders, { ...params });
    return res.data;
};

export const removeTelephonyProvider = async (
    params: TelephonyProviderRemoveRequest,
) => {
    const res = await execute<
        TelephonyProviderRemoveRequest,
        TelephonyProviderRemoveResponse
    >(TelephonyApi.TelephonyProviderRemove, { ...params });
    return res.data;
};

export const getPreSupportRequests = async (
    params: PreSupportRequestGetPreSupportRequestsRequest,
) => {
    const res = await execute<
        PreSupportRequestGetPreSupportRequestsRequest,
        PreSupportRequestGetPreSupportRequestsResponse
    >(TelephonyApi.PreSupportRequestGetPreSupportRequests, { ...params });
    return res.data;
};

export const getTelephonyRecordings = async (
    params: PreSupportRequestGetTelephonyRecordingsRequest,
) => {
    const res = await execute<
        PreSupportRequestGetTelephonyRecordingsRequest,
        PreSupportRequestGetTelephonyRecordingsResponse
    >(TelephonyApi.PreSupportRequestGetTelephonyRecordings, { ...params });
    return res.data;
};

export const updateCallerAccount = async (
    params: PreSupportRequestUpdateCallerAccountRequest,
) => {
    const res = await execute<
        PreSupportRequestUpdateCallerAccountRequest,
        PreSupportRequestUpdateCallerAccountResponse
    >(TelephonyApi.PreSupportRequestUpdateCallerAccount, { ...params });
    return res.data;
};
