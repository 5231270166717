interface IFormatAccountData {
    name: string;
    firstName?: string;
    surname?: string;
    middleName?: string;
}

const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatAccountName = ({
    name,
    firstName,
    surname,
    middleName,
}: IFormatAccountData) => {
    let result = ``;
    if (surname) {
        result += capitalizeFirstLetter(surname);
        if (firstName) {
            result += ` ${firstName[0].toUpperCase()}.`;
        }
        if (middleName) {
            result += ` ${middleName[0].toUpperCase()}.`;
        }
    } else if (firstName) {
        result += firstName;
        if (middleName) {
            result += ` ${middleName[0].toUpperCase()}.`;
        }
    }
    return result.length ? result : name;
};

export const formatFullAccountName = ({
    firstName,
    surname,
    middleName,
}: IFormatAccountData) => {
    let result = ``;
    if (surname) {
        result += capitalizeFirstLetter(surname);
        if (firstName) {
            result += ` ${firstName}`;
        }
        if (middleName) {
            result += ` ${middleName}`;
        }
    } else if (firstName) {
        result += firstName;
        if (middleName) {
            result += ` ${middleName}`;
        }
    }
    return result;
};
