const overrides = {
    MuiCardHeader: {
        action: {
            marginTop: '-4px',
            marginRight: '-4px',
        },
    },
    MuiPickersDay: {
        day: {
            fontWeight: '300',
        },
    },
    MuiPickersYear: {
        root: {
            height: '64px',
        },
    },
    MuiPickersCalendar: {
        transitionContainer: {
            marginTop: '6px',
        },
    },
    MuiPickersCalendarHeader: {
        iconButton: {
            backgroundColor: 'transparent',
            '& > *': {
                backgroundColor: 'transparent',
            },
        },
        switchHeader: {
            marginTop: '2px',
            marginBottom: '4px',
        },
    },
    MuiPickersClock: {
        container: {
            margin: `32px 0 4px`,
        },
    },
    MuiPickersClockNumber: {
        clockNumber: {
            left: `calc(50% - 16px)`,
            width: '32px',
            height: '32px',
        },
    },
    MuiPickerDTHeader: {
        dateHeader: {
            '& h4': {
                fontSize: '2.125rem',
                fontWeight: 400,
            },
        },
        timeHeader: {
            '& h3': {
                fontSize: '3rem',
                fontWeight: 400,
            },
        },
    },
    MuiPickersTimePicker: {
        hourMinuteLabel: {
            '& h2': {
                fontSize: '3.75rem',
                fontWeight: 300,
            },
        },
    },
    MuiPickersToolbar: {
        toolbar: {
            '& h4': {
                fontSize: '2.125rem',
                fontWeight: 400,
            },
        },
    },

    MuiSelect: {
        root: {
            fontWeight: 500,
            color: '#10346A',
        },
        icon: {
            color: '#10346A',
        },
    },
    MuiInputBase: {
        root: {},
    },
    MuiOutlinedInput: {
        root: {
            background: '#F1F6FC',
            borderRadius: 5,
            '&.Mui-focused': {
                borderRadius: 5,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#dbdfe8',
            },
        },

        notchedOutline: {
            borderColor: '#F1F6FC',
        },
    },

    MuiButton: {
        root: {
            padding: '8px 16px',
        },
    },
    MuiInputLabel: {
        shrink: {
            transform: `translate(12px, -4.5px) scale(0.75)`,
            zIndex: 1,
            color: '#10346A',
        },
        formControl: {
            transform: 'translate(15px, 20px) scale(1)',
            zIndex: 1,
        },
    },
    MuiSwitch: {
        root: {
            height: 46,
            width: 60,
        },
        track: {
            borderRadius: 23,
        },
        switchBase: {
            padding: 13,
            '&.Mui-checked': {
                transform: 'translateX(14px)',
            },
        },
    },
};

export default overrides;
