import { observer } from 'mobx-react';
import { AttachmentClient } from '../../services/objectStorage/models';
import React, { useContext } from 'react';
import CRMPageStoreContext from '../../modules/maintainer/pages/crm/store/context';
import { IconButton } from '@mui/material';
import { X } from 'react-feather';

const imgFileTypes = {
    jpg: true,
    jpeg: true,
    png: true,
    svg: true,
    jfif: true,
    gif: true,
    heic: true,
};

const heicType = {
    heic: true,
    HEIC: true,
};

export const isImage = (filename: string) => {
    const chunks = filename.split('.');
    const fileType = chunks[chunks.length - 1];
    return !!imgFileTypes[fileType];
};

export const isHeic = (filename: string) => {
    const chunks = filename.split('.');
    const fileType = chunks[chunks.length - 1];
    return !!heicType[fileType];
};

const videoFileTypes = {
    webm: true,
    mp4: true,
    mov: true,
};
export const isVideo = (filename: string) => {
    const chunks = filename.split('.');
    const fileType = chunks[chunks.length - 1];
    return !!videoFileTypes[fileType];
};

const MediaItem = observer(
    ({ children, item }: { item: AttachmentClient; children: any }) => {
        const pageStore = useContext(CRMPageStoreContext);

        return (
            <div style={{ position: 'relative' }}>
                {children}
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '1px 5px',
                        marginRight: 3,
                        marginTop: 3,
                    }}
                    size={'medium'}
                    onClick={() => {
                        pageStore.removeAttachment(item.filename);
                    }}
                >
                    <X width={20} height={20} />
                </IconButton>
            </div>
        );
    },
);

export default MediaItem;
