import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    getPreSupportRequests,
    updateCallerAccount,
} from '../../../../../services/telephony';
import { ExecutionStatus } from '../../../../../apiCommandsExecutor/api';
import {
    ClientEventPreSupportRequestCreateByCall,
    PreSupportRequestClient,
    PreSupportRequestMangoOfficeEventCallClient,
    PreSupportRequestStatusClient,
} from '../../../../../services/telephony/models';
import { DateRange } from 'rsuite/DateRangePicker';
import moment from 'moment';
import {
    SystemObjectT,
    SystemObjectType,
} from '../../../../../services/systemObjects/types';
import { getSystemObjects } from '../../../../../services/systemObjects';
import { getUsersData } from '../../../../../services/flatUsers';
import { AccountClientModel } from '../../../../../services/accounts/models';
import { AccountRole } from '../../../../../services/accounts/types';
import {
    getRequestsByFlatId,
    removeLinkedPreSupportRequest,
    updateSupportRequest,
} from '../../../../../services/crm';
import { SupportRequestClient } from '../../../../../services/crm/models';
import { FlatInfo } from '../../../../../services/flatOwners/models';
import { SupportRequestsUpdateSupportRequestRequest } from '../../../../../services/crm/requestsResponses';

export class TelephonyStore {
    preRequests: PreSupportRequestClient[] = [];
    total: number;
    callerNumber: string;
    callerAccountId: number;
    currentDates: DateRange = [
        moment().subtract(7, 'days').toDate(),
        moment().toDate(),
    ];
    currentFlatUserSearchText: string;
    flatUsersListModal = false;
    currentHouseId: number;
    currentFlatId: number;
    houses: { [s: string]: SystemObjectT } = {};
    flats: { [s: string]: SystemObjectT } = {};
    AccountClientModel: AccountClientModel[] = [];
    status: PreSupportRequestStatusClient;
    loading = false;
    pickRequestModal = false;
    uuid4pick: string;
    flatInfo: FlatInfo;
    requests: SupportRequestClient[] = [];
    audioLink: string;
    currentPreRequest: PreSupportRequestClient;
    incomingCallSnackbar = false;
    wsCallerInfo: ClientEventPreSupportRequestCreateByCall;
    currentCallerAccountId: number;

    constructor() {
        makeAutoObservable(this);
    }

    async getPreSupportRequests(skip: number, limit: number) {
        this.loading = true;
        const res = await getPreSupportRequests({
            callerAccountId: this.callerAccountId ? this.callerAccountId : null,
            callerNumber: this.callerNumber
                ? String(parseInt(this.callerNumber))
                : null,
            fromDate: this.currentDates ? this.currentDates[0] : null,
            limit: limit,
            skip: skip,
            source: null,
            status: this.status ? this.status : null,
            toDate: this.currentDates ? this.currentDates[1] : null,
        });
        if (res.executionStatus === ExecutionStatus.finished) {
            runInAction(() => {
                this.preRequests = res.preSupportRequests;
                this.total = res.total;
            });
        }
        this.loading = false;
    }

    async updateCurrentPreSupportRequest() {
        const res = await getPreSupportRequests({
            callerAccountId: this.callerAccountId ? this.callerAccountId : null,
            callerNumber: this.callerNumber
                ? String(parseInt(this.callerNumber))
                : null,
            fromDate: this.currentDates ? this.currentDates[0] : null,
            limit: 0,
            skip: 0,
            source: null,
            status: this.status ? this.status : null,
            toDate: this.currentDates ? this.currentDates[1] : null,
        });
        if (res.executionStatus === ExecutionStatus.finished) {
            runInAction(() => {
                const currentReq = res.preSupportRequests.find(
                    (i) => i.uuid === this.currentPreRequest.uuid,
                );
                this.setCurrentPreReq(currentReq);
                this.preRequests = this.preRequests.map((i) =>
                    i.uuid !== currentReq.uuid ? i : currentReq,
                );
            });
        }
    }

    async getHouses(orgBranchIds: number[]) {
        const response = await getSystemObjects({
            types: [SystemObjectType.House],
            organizationBranchesIds: orgBranchIds,
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.houses = map;
        });
    }

    async removeLinkedPreSupportRequest(requestId: number) {
        const response = await removeLinkedPreSupportRequest({
            supportRequestId: requestId,
            preSupportRequestUuid: this.currentPreRequest.uuid,
        });
        return response !== null;
    }

    async updateSupportRequest(
        params: SupportRequestsUpdateSupportRequestRequest,
    ) {
        const response = await updateSupportRequest(params);
        return response === null;
    }

    async getFlats() {
        const response = await getSystemObjects({
            types: [SystemObjectType.Flat],
            systemObjectsIds: [this.currentHouseId],
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.flats = map;
        });
    }

    async getFlatUsersData() {
        const response = await getUsersData({
            flatId: this.currentFlatId,
            roles: [AccountRole.HouseResident],
        });
        this.setUsers(response.accounts);
    }

    async getRequestsByFlatId() {
        const response = await getRequestsByFlatId({
            flatId: this.flatInfo.flatId,
        });
        this.requests = response.supportRequests;
    }

    async updateCallerAccount(userId: number | null) {
        const response = await updateCallerAccount({
            callerAccountId: userId,
            preSupportRequestUuid: this.currentPreRequest.uuid,
        });
        return response === null;
    }

    @action
    setWsCallerInfo(info: ClientEventPreSupportRequestCreateByCall) {
        this.wsCallerInfo = info;
        this.incomingCallSnackbar = true;
    }

    @action
    openIncomingCallSnackbar() {
        this.incomingCallSnackbar = true;
    }

    @action
    closeIncomingCallSnackbar() {
        this.incomingCallSnackbar = false;
    }

    @action
    openPickRequestModal() {
        this.uuid4pick = this.currentPreRequest.uuid;
        this.flatInfo = (
            this
                .currentPreRequest as PreSupportRequestMangoOfficeEventCallClient
        ).callerAccountDetails.flats[0];
        this.pickRequestModal = true;
    }

    @action
    setCurrentPreReq(req: PreSupportRequestClient) {
        this.currentPreRequest = req;
    }

    @action
    closePickRequestModal() {
        this.pickRequestModal = false;
    }

    @action
    setAudioLink(link: string) {
        this.audioLink = link;
    }

    @action
    cleanStore() {
        this.uuid4pick = undefined;
        this.flatInfo = undefined;
    }

    @action
    setUsers(usersData: AccountClientModel[]) {
        this.AccountClientModel = usersData;
    }

    @action
    setCallerNumber(text: string) {
        this.callerNumber = text;
    }

    @action
    setHouseId(id: number) {
        this.currentHouseId = id;
    }

    @action
    setFlatId(id: number) {
        this.currentFlatId = id;
    }

    @action
    setCurrentDeadline(date: DateRange | null) {
        this.currentDates = date;
    }

    @action
    setCurrentSearchText(text: string) {
        this.currentFlatUserSearchText = text;
    }

    @action
    setCallerAccountId(id: number) {
        this.callerAccountId = id;
    }

    @action
    openFlatUsersModal(flatId?: number, houseId?: number, callerId?: number) {
        this.setFlatId(flatId);
        this.setHouseId(houseId);
        this.currentCallerAccountId = callerId;
        this.flatUsersListModal = true;
    }

    @action
    closeFlatUsersModal() {
        this.flatUsersListModal = false;
        this.currentFlatId = null;
        this.currentHouseId = null;
        this.currentCallerAccountId = null;
    }

    @action
    setStatus(status: PreSupportRequestStatusClient | null) {
        this.status = status;
    }
}

const telephonyStore = new TelephonyStore();

export default telephonyStore;
