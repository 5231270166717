import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import LogItemsModalStoreContext from './store/context';
import { Paper } from '../libs';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Popover,
} from '@mui/material';

const PopoverFilters = () => {
    const logsPageStore = useContext(LogItemsModalStoreContext);

    const handleArchiveTypesChange = (type: string) => {
        if (logsPageStore.archiveTypes.includes(type)) {
            logsPageStore.setArchiveTypes(
                logsPageStore.archiveTypes.filter((i) => i !== type),
            );
        } else {
            logsPageStore.setArchiveTypes([
                ...logsPageStore.archiveTypes,
                type,
            ]);
        }
    };

    const handleSensorTypesChange = (type: string) => {
        if (logsPageStore.sensorTypes.includes(type)) {
            logsPageStore.setSensorTypes(
                logsPageStore.sensorTypes.filter((i) => i !== type),
            );
        } else {
            logsPageStore.setSensorTypes([...logsPageStore.sensorTypes, type]);
        }
    };

    const handleSuccessTypeChange = (type: boolean) => {
        if (logsPageStore.success === type) {
            logsPageStore.setSuccessType(null);
        } else {
            logsPageStore.setSuccessType(type);
        }
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleHover = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);
    const id = open ? 'simple-popover' : undefined;
    const popoverOpen = Boolean(anchorEl);

    return (
        <>
            <Button color={'primary'} onClick={(e) => handleHover(e)}>
                Фильтры
            </Button>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { minWidth: '15%' },
                }}
            >
                <Paper p={4}>
                    <FormGroup>
                        <span
                            style={{
                                fontSize: 14,
                                fontFamily: 'Formular',
                                color: '#A7A7A7',
                            }}
                        >
                            По наличию ошибок
                        </span>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.success === true}
                                    onChange={() =>
                                        handleSuccessTypeChange(true)
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Только успешные
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.success === false}
                                    onChange={() =>
                                        handleSuccessTypeChange(false)
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Только с ошибками
                                </span>
                            }
                            style={{
                                fontSize: 14,
                                fontFamily: 'Formular',
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <span
                            style={{
                                fontSize: 14,
                                fontFamily: 'Formular',
                                color: '#A7A7A7',
                                marginTop: 10,
                            }}
                        >
                            По ресурсу
                        </span>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.sensorTypes.includes(
                                        'ColdWater',
                                    )}
                                    onChange={() =>
                                        handleSensorTypesChange('ColdWater')
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    ХВС
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.sensorTypes.includes(
                                        'HotWater',
                                    )}
                                    onChange={() =>
                                        handleSensorTypesChange('HotWater')
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    ГВС
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.sensorTypes.includes(
                                        'Heat',
                                    )}
                                    onChange={() =>
                                        handleSensorTypesChange('Heat')
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Тепло
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.sensorTypes.includes(
                                        'ElectricEnergy',
                                    )}
                                    onChange={() =>
                                        handleSensorTypesChange(
                                            'ElectricEnergy',
                                        )
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Электроэнергия
                                </span>
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <span
                            style={{
                                fontSize: 14,
                                fontFamily: 'Formular',
                                color: '#A7A7A7',
                                marginTop: 10,
                            }}
                        >
                            По типу измерений
                        </span>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.archiveTypes.includes(
                                        'CurrentValues',
                                    )}
                                    onChange={() =>
                                        handleArchiveTypesChange(
                                            'CurrentValues',
                                        )
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Текущие
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.archiveTypes.includes(
                                        'ArchiveDaily',
                                    )}
                                    onChange={() =>
                                        handleArchiveTypesChange('ArchiveDaily')
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Дневные архивы
                                </span>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={logsPageStore.archiveTypes.includes(
                                        'ArchiveMonthly',
                                    )}
                                    onChange={() =>
                                        handleArchiveTypesChange(
                                            'ArchiveMonthly',
                                        )
                                    }
                                    size={'small'}
                                    color={'primary'}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'Formular',
                                    }}
                                >
                                    Месячные архивы
                                </span>
                            }
                        />
                    </FormGroup>
                </Paper>
            </Popover>
        </>
    );
};

export default observer(PopoverFilters);
