import { execute } from '../../apiCommandsExecutor';
import { AuthAPI } from './methods';
import {
    AuthRefreshTokenPairRequest,
    AuthRefreshTokenPairResponse,
    AuthSigninRequest,
    AuthSigninResponse,
    AuthSignupRequest,
    AuthSignupResponse,
} from './requestsResponses';
import { SignUpParams } from './models';

export const signIn = async (params: { login: string; password: string }) => {
    const { login, password } = params;
    const res = await execute<AuthSigninRequest, AuthSigninResponse>(
        AuthAPI.AuthSignin,
        {
            login,
            password,
        },
    );
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const signUp = async (params: SignUpParams) => {
    const res = await execute<AuthSignupRequest, AuthSignupResponse>(
        AuthAPI.AuthSignup,
        {
            accountData: {
                email: params.email,
                phoneNumber: params.phoneNumber,
                name: params.name,
                firstName: params.firstName,
                surname: params.surname,
                middleName: params.middleName,
            },
            password: params.password,
            organizationName: params.organisationName,
            login: params.login,
        },
    );
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const refreshTokenPair = async (params: AuthRefreshTokenPairRequest) => {
    const res = await execute<
        AuthRefreshTokenPairRequest,
        AuthRefreshTokenPairResponse
    >(AuthAPI.AuthRefreshTokenPair, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

// export const recoverPassword = async (login: string, password: string) => {
//     const res = await execute<AuthSigninRequest, AuthSigninResponse>(
//         AuthAPI.AuthSignin,
//         {
//             login,
//             password,
//         }
//     );
// };
