import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ChatMessageClient } from '../../../services/chat/models';
import { useStores } from '../../../hooks';
import { Avatar, Paper } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru';
import ChatModalStoreContext from './store/context';
import ChatMessageAttachment from './chatMessageAttachment';

interface chatMessageProps {
    item: ChatMessageClient;
}

const DateDividerLabel = (props: { text: string }) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    marginTop: 5,
                    display: 'flex',
                    backgroundColor: '#3776f017',
                    justifyContent: 'center',
                    fontFamily: 'Formular',
                    fontWeight: 500,
                    fontSize: 12,
                    padding: '5px 10px',
                    borderRadius: '60px',
                    //minWidth: '140px'
                }}
            >
                {props.text}
            </div>
        </div>
    );
};

const getAvatarStyles = (isMyMessage: boolean) => {
    return {
        marginRight: isMyMessage ? 0 : 10,
        marginLeft: isMyMessage ? 10 : 0,
        width: 30,
        height: 30,
        borderRadius: '50%',
        marginTop: 0,
    };
};

const urlRegex = /(https?:\/\/[^\s]+)/g;

const ChatMessageItem = (props: chatMessageProps) => {
    const { authStore } = useStores();
    const chatStore = useContext(ChatModalStoreContext);
    const isMyMessage = () => {
        return props.item.accountId === authStore.currentUser?.id;
    };

    const testIndexAvatar = () => {
        const index = chatStore.chatMessages.findIndex((i) => i === props.item);
        const nextElement = chatStore.chatMessages[index + 1];
        return nextElement?.accountId !== props.item.accountId;
    };

    const testIndexName = () => {
        const index = chatStore.chatMessages.findIndex((i) => i === props.item);
        const prevElement = chatStore.chatMessages[index - 1];
        return prevElement?.accountId !== props.item.accountId;
    };

    const testIndexDate = () => {
        const index = chatStore.chatMessages.findIndex((i) => i === props.item);
        const prevElement = chatStore.chatMessages[index - 1];
        if (
            moment(prevElement?.createdAt).format('L') !==
                moment(props.item.createdAt).format('L') &&
            moment(props.item.createdAt).format('L') === moment().format('L')
        ) {
            return <DateDividerLabel text='Сегодня' />;
        } else if (
            moment(prevElement?.createdAt).format('L') !==
                moment(props.item.createdAt).format('L') &&
            moment(props.item.createdAt).format('L') ===
                moment().subtract(1, 'days').format('L')
        ) {
            return <DateDividerLabel text='Вчера' />;
        } else if (
            moment(prevElement?.createdAt).format('L') !==
            moment(props.item.createdAt).format('L')
        ) {
            return (
                <DateDividerLabel
                    text={moment(props.item.createdAt).format('D MMMM')}
                />
            );
        }
    };

    return (
        <>
            {testIndexDate()}
            <div
                style={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: isMyMessage() ? 'row-reverse' : 'row',
                    alignItems: 'end',
                }}
            >
                {testIndexAvatar() ? (
                    props.item.avatar ? (
                        <Avatar
                            src={props.item.avatar.downloadLink}
                            style={getAvatarStyles(isMyMessage())}
                        />
                    ) : (
                        <Avatar style={getAvatarStyles(isMyMessage())}>
                            {props.item.accountName[0]}
                        </Avatar>
                    )
                ) : (
                    <div
                        style={{
                            marginRight: isMyMessage() ? 0 : 40,
                            marginLeft: isMyMessage() ? 40 : 0,
                        }}
                    ></div>
                )}
                <div
                    style={{
                        width: 'auto',
                        maxWidth: '80%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Paper
                        style={{
                            padding: '5px 10px',
                            borderRadius: 10,
                        }}
                    >
                        {testIndexName() && (
                            <span
                                style={{
                                    fontFamily: 'Formular',
                                    fontWeight: '600',
                                    fontSize: 14,
                                    color: '#2A73FF',
                                    marginBottom: -3,
                                }}
                            >
                                {props.item.accountName}
                            </span>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {props.item.content
                                        ? props.item.content
                                              .split('\n')
                                              .map((i, index) =>
                                                  i.split(urlRegex).map((s) => {
                                                      if (urlRegex.test(s)) {
                                                          return (
                                                              <span
                                                                  key={`${s} + ${index}`}
                                                                  onClick={() =>
                                                                      window.open(
                                                                          s,
                                                                      )
                                                                  }
                                                                  style={{
                                                                      fontFamily:
                                                                          'Formular',
                                                                      fontWeight:
                                                                          '400',
                                                                      fontSize: 14,
                                                                      color: '#2A73FF',
                                                                      cursor: 'pointer',
                                                                  }}
                                                              >
                                                                  {s}
                                                              </span>
                                                          );
                                                      } else {
                                                          return (
                                                              <span
                                                                  key={`${s} + ${index}`}
                                                                  style={{
                                                                      fontFamily:
                                                                          'Formular',
                                                                      fontWeight:
                                                                          '400',
                                                                      fontSize: 14,
                                                                      color: 'black',
                                                                  }}
                                                              >
                                                                  {s}
                                                              </span>
                                                          );
                                                      }
                                                  }),
                                              )
                                        : null}
                                </div>
                                {props.item.attachments.length > 0 ? (
                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {props.item.attachments
                                            .slice()
                                            .sort((a) => {
                                                if (
                                                    a.fileName.includes(
                                                        'docx',
                                                    ) ||
                                                    a.fileName.includes(
                                                        'xlsx',
                                                    ) ||
                                                    a.fileName.includes('pdf')
                                                ) {
                                                    return -1;
                                                } else return 1;
                                            })
                                            .map((i, index) => (
                                                <ChatMessageAttachment
                                                    item={i}
                                                    allAttachments={
                                                        props.item.attachments
                                                    }
                                                    key={index}
                                                />
                                            ))}
                                    </div>
                                ) : null}
                            </div>
                            <span
                                style={{
                                    color: '#C0C0C0',
                                    alignSelf: 'flex-end',
                                    fontSize: 12,
                                    marginLeft: 10,
                                }}
                            >
                                {moment(props.item.createdAt).format('LT')}
                            </span>
                        </div>
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default observer(ChatMessageItem);
