import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import LogItemsModalStoreContext from './store/context';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { LogItem } from '../../../services/logs/models';
import moment from 'moment';
import Loader from '../Loader';
import { SystemObjectType } from '../../../services/systemObjects/types';
import PopoverFilters from './Filters';
import LogItemForHouse from './LogItemForHouse';
import { ArchiveTypes } from '../../../services/flatView/models';

interface sensorLogProps {
    log: LogItem;
}

export const LogItemStatus = (props: sensorLogProps) => {
    const [logInfo, setLogInfo] = useState(false);

    if (props.log.success) {
        return (
            <p style={{ color: '#2e942e', padding: 3 }}>
                {`[${moment(props.log.addedAt.toString()).format(
                    'DD.MM.YYYY HH:mm',
                )}] [${
                    ArchiveTypes[props.log.measurementSourceId]
                }] Операция успешно выполнена`}
            </p>
        );
    } else {
        if (!logInfo) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                    }}
                >
                    <span style={{ color: '#cc1100' }}>
                        {`[${moment(props.log.addedAt.toString()).format(
                            'DD.MM.YYYY HH:mm',
                        )}] [${ArchiveTypes[props.log.measurementSourceId]}] ${
                            props.log.displayText.length > 0
                                ? props.log.displayText
                                : 'Неизвестная ошибка'
                        }`}
                    </span>
                    <span
                        onClick={() => setLogInfo(!logInfo)}
                        style={{
                            color: '#2A73FF',
                            cursor: 'pointer',
                            marginTop: 3,
                        }}
                    >
                        Показать технические детали
                    </span>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                    }}
                >
                    <span style={{ color: '#cc1100' }}>
                        {`[${moment(props.log.addedAt.toString()).format(
                            'DD.MM.YYYY HH:mm',
                        )}] [${ArchiveTypes[props.log.measurementSourceId]}] ${
                            props.log.displayText.length > 0
                                ? props.log.displayText
                                : 'Неизвестная ошибка'
                        }`}
                    </span>
                    <div
                        style={{
                            borderLeft: '2px solid #D71600',
                            padding: '0 10px',
                            marginLeft: 20,
                            marginTop: 10,
                        }}
                    >
                        <span>{props.log.text}</span>
                    </div>
                    <span
                        onClick={() => setLogInfo(!logInfo)}
                        style={{
                            color: '#2A73FF',
                            cursor: 'pointer',
                            marginTop: 3,
                        }}
                    >
                        Скрыть технические детали
                    </span>
                </div>
            );
        }
    }
};

const LogItemsModalComponent = () => {
    const logsPageStore = useContext(LogItemsModalStoreContext);
    const isOpen = logsPageStore.isOpen;
    const responseLogs = logsPageStore.logs;
    const responseLogsForHouse = logsPageStore.houseLogs;
    const loading = logsPageStore.loading;
    const bottomRef = useRef(null);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (!logsPageStore.systemObjectId) return;
        logsPageStore.currentType === SystemObjectType.Sensor &&
            logsPageStore.getItems();
        logsPageStore.currentType === SystemObjectType.House && setLoad(true);
        logsPageStore.currentType === SystemObjectType.House &&
            logsPageStore
                .getItemsForHouse()
                .then(() => {
                    const timeout = setTimeout(() => {
                        if (!bottomRef.current) return;
                        bottomRef.current.scrollIntoView({
                            behavior: 'smooth',
                        });
                        !logsPageStore.systemObjectId && clearTimeout(timeout);
                    }, 1000);
                })
                .then(() => setLoad(false));
        const interval = setInterval(async () => {
            logsPageStore.currentType === SystemObjectType.House &&
                !logsPageStore.systemObjectId &&
                clearInterval(interval);
            logsPageStore.currentType === SystemObjectType.House &&
                !load &&
                (await logsPageStore.getNewItemsForHouse());
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [
        logsPageStore.systemObjectId,
        logsPageStore.archiveTypes,
        logsPageStore.sensorTypes,
        logsPageStore.success,
    ]);

    return (
        <Dialog
            open={isOpen}
            onClose={logsPageStore.closeModal.bind(logsPageStore)}
            fullWidth={true}
            maxWidth={'sm'}
            PaperProps={{
                style: { minHeight: 'calc(100% - 64px)' },
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    marginRight: 20,
                }}
            >
                <DialogTitle>Логи опроса</DialogTitle>
                <DialogActions>
                    {logsPageStore.hasMoreLogs &&
                        logsPageStore.currentType === SystemObjectType.House &&
                        !logsPageStore.loading && (
                            <Button
                                color={'primary'}
                                onClick={async () => {
                                    setLoad(true);
                                    await logsPageStore.getLatestItemsForHouse(
                                        logsPageStore.pageNumber + 1,
                                    );
                                    setLoad(false);
                                }}
                            >
                                Загрузить предыдущие
                            </Button>
                        )}
                    {!loading &&
                        logsPageStore.currentType ===
                            SystemObjectType.House && <PopoverFilters />}
                </DialogActions>
            </div>
            <DialogContent>
                {loading === true && <Loader />}
                {responseLogs.length === 0 &&
                    logsPageStore.currentType === SystemObjectType.Sensor &&
                    loading === false &&
                    ' Нет логов опроса. Убедитесь, что включен опрос дома и настройки счетчика корректны.'}
                {responseLogs.length > 0 &&
                    loading === false &&
                    logsPageStore.currentType === SystemObjectType.Sensor &&
                    responseLogs.map((item) => {
                        return (
                            <LogItemStatus
                                log={item}
                                key={`${item.addedAt} + ${item.sensorSerialNumber}`}
                            />
                        );
                    })}
                {responseLogsForHouse.length === 0 &&
                    logsPageStore.currentType === SystemObjectType.House &&
                    loading === false &&
                    ' Нет логов опроса. Убедитесь, что включен опрос дома.'}
                {responseLogsForHouse.length > 0 &&
                    loading === false &&
                    logsPageStore.currentType === SystemObjectType.House &&
                    responseLogsForHouse.map((item) => {
                        return (
                            <LogItemForHouse
                                log={item}
                                key={`${item.addedAt} + ${item.sensorSerialNumber}`}
                            />
                        );
                    })}
                {logsPageStore.systemObjectId ? (
                    <div ref={bottomRef}></div>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={logsPageStore.closeModal.bind(logsPageStore)}
                    color='primary'
                >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(LogItemsModalComponent);
