import { execute } from '../../apiCommandsExecutor';
import {
    FlatsPushSensorMeasurementsManuallyRequest,
    FlatsPushSensorMeasurementsManuallyResponse,
    HousesUpdateHouseResidentSignUpConfigRequest,
    HousesUpdateHouseResidentSignUpConfigResponse,
    HousesUpdateNotificationSettingsRequest,
    HousesUpdateNotificationSettingsResponse,
    SystemObjectGetSystemObjectByIdRequest,
    SystemObjectGetSystemObjectByIdResponse,
    SystemObjectsCreateConnectionLineRequest,
    SystemObjectsCreateConnectionLineResponse,
    SystemObjectsCreateCopyRequest,
    SystemObjectsCreateCopyResponse,
    SystemObjectsCreateFlatRequest,
    SystemObjectsCreateFlatResponse,
    SystemObjectsCreateHouseRequest,
    SystemObjectsCreateHouseResponse,
    SystemObjectsCreateHubRequest,
    SystemObjectsCreateHubResponse,
    SystemObjectsCreatePollingGroupRequest,
    SystemObjectsCreatePollingGroupResponse,
    SystemObjectsCreateSensorPlaceholderRequest,
    SystemObjectsCreateSensorPlaceholderResponse,
    SystemObjectsCreateSensorRequest,
    SystemObjectsCreateSensorResponse,
    SystemObjectsGenerateHouseRequest,
    SystemObjectsGenerateHouseResponse,
    SystemObjectsGetSystemObjectsRequest,
    SystemObjectsGetSystemObjectsResponse,
    SystemObjectsMoveHouseRequest,
    SystemObjectsMoveHouseResponse,
    SystemObjectsRemoveSystemObjectRequest,
    SystemObjectsRemoveSystemObjectResponse,
    SystemObjectsUpdateConnectionLineRequest,
    SystemObjectsUpdateConnectionLineResponse,
    SystemObjectsUpdateExternalIdentRequest,
    SystemObjectsUpdateExternalIdentResponse,
    SystemObjectsUpdateFlatRequest,
    SystemObjectsUpdateFlatResponse,
    SystemObjectsUpdateHouseRequest,
    SystemObjectsUpdateHouseResponse,
    SystemObjectsUpdateHubRequest,
    SystemObjectsUpdateManualSendingNotificationsEnabledInHouseRequest,
    SystemObjectsUpdateManualSendingNotificationsEnabledInHouseResponse,
    SystemObjectsUpdatePollingConfigRequest,
    SystemObjectsUpdatePollingConfigResponse,
    SystemObjectsUpdateSensorPlaceholderRequest,
    SystemObjectsUpdateSensorPlaceholderResponse,
    SystemObjectsUpdateSensorRequest,
    SystemObjectsUpdateSensorResponse,
} from './requestsResponses';
import { IGetSystemObjectsParams } from './models';
import { SystemObjectsAPI } from './methods';

export const getSystemObjects = async (params: IGetSystemObjectsParams) => {
    const res = await execute<
        SystemObjectsGetSystemObjectsRequest,
        SystemObjectsGetSystemObjectsResponse
    >(SystemObjectsAPI.SystemObjectsGetSystemObjects, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createHouse = async (params: SystemObjectsCreateHouseRequest) => {
    const res = await execute<
        SystemObjectsCreateHouseRequest,
        SystemObjectsCreateHouseResponse
    >(SystemObjectsAPI.SystemObjectsCreateHouse, {
        parentId: params.parentId,
        orgBranchId: params.orgBranchId,
        data: {
            address: params.data.address,
            name: params.data.name,
            location: params.data.location,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateHouse = async (
    params: Partial<SystemObjectsUpdateHouseRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateHouseRequest,
        SystemObjectsUpdateHouseResponse
    >(SystemObjectsAPI.SystemObjectsUpdateHouse, {
        data: {
            address: params.data.address,
            name: params.data.name,
            location: params.data.location,
        },
        id: params.id,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createFlat = async (
    params: Partial<SystemObjectsCreateFlatRequest>,
) => {
    const res = await execute<
        SystemObjectsCreateFlatRequest,
        SystemObjectsCreateFlatResponse
    >(SystemObjectsAPI.SystemObjectsCreateFlat, {
        parentId: params.parentId,
        data: {
            name: params.data.name,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateFlat = async (
    params: Partial<SystemObjectsUpdateFlatRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateFlatRequest,
        SystemObjectsUpdateFlatResponse
    >(SystemObjectsAPI.SystemObjectsUpdateFlat, {
        data: {
            name: params.data.name,
        },
        id: params.id,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createSensorPlaceholder = async (
    params: Partial<SystemObjectsCreateSensorPlaceholderRequest>,
) => {
    const res = await execute<
        SystemObjectsCreateSensorPlaceholderRequest,
        SystemObjectsCreateSensorPlaceholderResponse
    >(SystemObjectsAPI.SystemObjectsCreateSensorPlaceholder, {
        parentId: params.parentId,
        data: {
            name: params.data.name,
            sensorMeasurementKindId: params.data.sensorMeasurementKindId,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateSensorPlaceholder = async (
    params: Partial<SystemObjectsUpdateSensorPlaceholderRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateSensorPlaceholderRequest,
        SystemObjectsUpdateSensorPlaceholderResponse
    >(SystemObjectsAPI.SystemObjectsUpdateSensorPlaceholder, {
        id: params.id,
        data: {
            name: params.data.name,
            sensorMeasurementKindId: params.data.sensorMeasurementKindId,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createSensor = async (
    params: Partial<SystemObjectsCreateSensorRequest>,
) => {
    const res = await execute<
        SystemObjectsCreateSensorRequest,
        SystemObjectsCreateSensorResponse
    >(SystemObjectsAPI.SystemObjectsCreateSensor, {
        parentId: params.parentId,
        data: {
            additionalSettings: params.data.additionalSettings,
            sensorPassword: params.data.sensorPassword,
            sensorSerialNumber: params.data.sensorSerialNumber,
            connectionLineSystemObjectId:
                params.data.connectionLineSystemObjectId,
            name: params.data.name,
            sensorAddress: params.data.sensorAddress,
            sensorModelId: params.data.sensorModelId,
            sensorMeasurementKindId: params.data.sensorMeasurementKindId,
            impulseWeight: params.data.impulseWeight,
            initialMeasurement: params.data.initialMeasurement,
            initialImpulseCount: params.data.initialImpulseCount,
            decimalPlaces: params.data.decimalPlaces,
            onlyPollAfterDate: params.data.onlyPollAfterDate,
            nextVerificationDate: params.data.nextVerificationDate,
            isEncryptionEnabled: params.data.isEncryptionEnabled,
            status: params.data.status,
            statusComment: params.data.statusComment,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateSensor = async (
    params: Partial<SystemObjectsUpdateSensorRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateSensorRequest,
        SystemObjectsUpdateSensorResponse
    >(SystemObjectsAPI.SystemObjectsUpdateSensor, {
        id: params.id,
        data: {
            additionalSettings: params.data.additionalSettings,
            sensorSerialNumber: params.data.sensorSerialNumber,
            sensorPassword: params.data.sensorPassword,
            connectionLineSystemObjectId:
                params.data.connectionLineSystemObjectId,
            name: params.data.name,
            sensorAddress: params.data.sensorAddress,
            sensorModelId: params.data.sensorModelId,
            sensorMeasurementKindId: params.data.sensorMeasurementKindId,
            impulseWeight: params.data.impulseWeight,
            initialMeasurement: params.data.initialMeasurement,
            initialImpulseCount: params.data.initialImpulseCount,
            decimalPlaces: params.data.decimalPlaces,
            onlyPollAfterDate: params.data.onlyPollAfterDate,
            nextVerificationDate: params.data.nextVerificationDate,
            isEncryptionEnabled: params.data.isEncryptionEnabled,
            status: params.data.status,
            statusComment: params.data.statusComment,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createHub = async (
    params: Partial<SystemObjectsCreateHubRequest>,
) => {
    const res = await execute<
        SystemObjectsCreateHubRequest,
        SystemObjectsCreateHubResponse
    >(SystemObjectsAPI.SystemObjectsCreateHub, {
        parentId: params.parentId,
        data: {
            name: params.data.name,
            settings: params.data.settings,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateHub = async (
    params: Partial<SystemObjectsUpdateHubRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateHubRequest,
        SystemObjectsCreateHubResponse
    >(SystemObjectsAPI.SystemObjectsUpdateHub, {
        id: params.id,
        data: {
            name: params.data.name,
            settings: params.data.settings,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createConnectionLine = async (
    params: Partial<SystemObjectsCreateConnectionLineRequest>,
) => {
    const res = await execute<
        SystemObjectsCreateConnectionLineRequest,
        SystemObjectsCreateConnectionLineResponse
    >(SystemObjectsAPI.SystemObjectsCreateConnectionLine, {
        parentId: params.parentId,
        data: {
            name: params.data.name,
            settings: params.data.settings,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateConnectionLine = async (
    params: Partial<SystemObjectsUpdateConnectionLineRequest>,
) => {
    const res = await execute<
        SystemObjectsUpdateConnectionLineRequest,
        SystemObjectsUpdateConnectionLineResponse
    >(SystemObjectsAPI.SystemObjectsUpdateConnectionLine, {
        id: params.id,
        data: {
            name: params.data.name,
            settings: params.data.settings,
        },
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const generateHouseTemplate = async (
    params: SystemObjectsGenerateHouseRequest,
) => {
    const res = await execute<
        SystemObjectsGenerateHouseRequest,
        SystemObjectsGenerateHouseResponse
    >(SystemObjectsAPI.SystemObjectsGenerateHouse, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const removeSystemObjectById = async (
    params: SystemObjectsRemoveSystemObjectRequest,
) => {
    const res = await execute<
        SystemObjectsRemoveSystemObjectRequest,
        SystemObjectsRemoveSystemObjectResponse
    >(SystemObjectsAPI.SystemObjectsRemoveSystemObject, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const moveHouse = async (
    params: Omit<SystemObjectsMoveHouseRequest, 'clientSyncId'>,
) => {
    const res = await execute<
        SystemObjectsMoveHouseRequest,
        SystemObjectsMoveHouseResponse
    >(SystemObjectsAPI.SystemObjectsMoveHouse, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSystemObjectById = async (
    params: SystemObjectGetSystemObjectByIdRequest,
) => {
    const res = await execute<
        SystemObjectGetSystemObjectByIdRequest,
        SystemObjectGetSystemObjectByIdResponse
    >(SystemObjectsAPI.SystemObjectGetSystemObjectById, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updatePollingConfig = async (
    params: SystemObjectsUpdatePollingConfigRequest,
) => {
    const res = await execute<
        SystemObjectsUpdatePollingConfigRequest,
        SystemObjectsUpdatePollingConfigResponse
    >(SystemObjectsAPI.SystemObjectsUpdatePollingConfig, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createPollingGroup = async (
    params: SystemObjectsCreatePollingGroupRequest,
) => {
    const res = await execute<
        SystemObjectsCreatePollingGroupRequest,
        SystemObjectsCreatePollingGroupResponse
    >(SystemObjectsAPI.SystemObjectsCreatePollingGroup, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateNotificationSettings = async (
    params: HousesUpdateNotificationSettingsRequest,
) => {
    const res = await execute<
        HousesUpdateNotificationSettingsRequest,
        HousesUpdateNotificationSettingsResponse
    >(SystemObjectsAPI.HousesUpdateNotificationSettings, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateManualSendingNotifications = async (
    params: SystemObjectsUpdateManualSendingNotificationsEnabledInHouseRequest,
) => {
    const res = await execute<
        SystemObjectsUpdateManualSendingNotificationsEnabledInHouseRequest,
        SystemObjectsUpdateManualSendingNotificationsEnabledInHouseResponse
    >(
        SystemObjectsAPI.SystemObjectsUpdateManualSendingNotificationsEnabledInHouse,
        { ...params },
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createCopy = async (params: SystemObjectsCreateCopyRequest) => {
    const res = await execute<
        SystemObjectsCreateCopyRequest,
        SystemObjectsCreateCopyResponse
    >(SystemObjectsAPI.SystemObjectsCreateCopy, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const addMeasurements = async (
    params: FlatsPushSensorMeasurementsManuallyRequest,
) => {
    const res = await execute<
        FlatsPushSensorMeasurementsManuallyRequest,
        FlatsPushSensorMeasurementsManuallyResponse
    >(SystemObjectsAPI.FlatsPushSensorMeasurementsManually, { ...params });
    return res.data;
};

export const updateExternalId = async (
    params: SystemObjectsUpdateExternalIdentRequest,
) => {
    const res = await execute<
        SystemObjectsUpdateExternalIdentRequest,
        SystemObjectsUpdateExternalIdentResponse
    >(SystemObjectsAPI.SystemObjectsUpdateExternalIdent, { ...params });
    return res.data;
};

export const updateHouseSignUpConfig = async (
    params: HousesUpdateHouseResidentSignUpConfigRequest,
) => {
    const res = await execute<
        HousesUpdateHouseResidentSignUpConfigRequest,
        HousesUpdateHouseResidentSignUpConfigResponse
    >(SystemObjectsAPI.HousesUpdateHouseResidentSignUpConfig, { ...params });
    return res.data;
};
