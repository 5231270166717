import {
    FlatsPushSensorMeasurementsManuallyRequest,
    FlatsPushSensorMeasurementsManuallyResponse,
    SensorMeasurementsRemoveSensorMeasurementsRequest,
    SensorMeasurementsRemoveSensorMeasurementsResponse,
} from './requestsResponses';
import { execute } from '../../apiCommandsExecutor';
import { SensorMeasurementsAPI } from './methods';

export const removeSensorMeasurements = async (
    params: SensorMeasurementsRemoveSensorMeasurementsRequest,
) => {
    const res = await execute<
        SensorMeasurementsRemoveSensorMeasurementsRequest,
        SensorMeasurementsRemoveSensorMeasurementsResponse
    >(SensorMeasurementsAPI.SensorMeasurementsRemoveSensorMeasurements, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const pushMeasurementsManually = async (
    params: FlatsPushSensorMeasurementsManuallyRequest,
) => {
    const res = await execute<
        FlatsPushSensorMeasurementsManuallyRequest,
        FlatsPushSensorMeasurementsManuallyResponse
    >(SensorMeasurementsAPI.FlatsPushSensorMeasurementsManually, { ...params });
    return res.data;
};
