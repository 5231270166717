import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { createFlatChat, getFlatChatList } from '../../../../../services/chat';
import {
    FlatChat,
    FlatChatLastMessage,
} from '../../../../../services/chat/models';
import { getSystemObjects } from '../../../../../services/systemObjects';
import {
    SystemObjectT,
    SystemObjectType,
} from '../../../../../services/systemObjects/types';

export class ChatListStore {
    currentHouseId: number;
    currentFlatId: number;
    flatList: FlatChat[] = [];
    total = 0;
    flatChatLastMessages: FlatChatLastMessage[] = [];
    systemObjects: {
        [s: string]: SystemObjectT[] | null;
    } = {};
    id2SystemObjectMap: { [s: string]: SystemObjectT } = {};
    id2SystemObjectMapFlats: { [s: string]: SystemObjectT } = {};
    filterByHouseId: number[] = [];
    addChatModal = false;
    searchCount: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    async getFlatChatList(skip: number, orgBranchId: number) {
        const response = await getFlatChatList({
            orgBranchIds: [orgBranchId],
            houseIds: this.filterByHouseId,
            limit: 120,
            skip: skip,
        });
        runInAction(() => {
            this.flatChatLastMessages = response.flatChatLastMessages;
        });
    }

    async createFlatChat() {
        const response = await createFlatChat({
            title: `Чат с УК, ${
                this.id2SystemObjectMap[this.currentHouseId].data.name
            }, ${this.id2SystemObjectMapFlats[this.currentFlatId].data.name}`,
            flatId: this.currentFlatId,
        });
        return response === null;
    }

    async getHouses(orgBranchId: number) {
        const response = await getSystemObjects({
            organizationBranchesIds: [orgBranchId],
            types: [SystemObjectType.House],
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.systemObjects = response.systemObjectsByParentId;
            this.id2SystemObjectMap = map;
        });
    }

    async getSystemObjects() {
        const response = await getSystemObjects({
            systemObjectsIds: [this.currentHouseId],
            types: [SystemObjectType.Flat],
        });
        runInAction(() => {
            const flatMap = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    flatMap[item.id] = item;
                });
            });
            this.id2SystemObjectMapFlats = flatMap;
        });
    }

    @action
    setHouseSystemObjectId(houseId: number) {
        this.currentHouseId = houseId;
    }

    @action
    setFlatSystemObjectId(flatId: number) {
        this.currentFlatId = flatId;
    }

    @action
    setTotal(total: number) {
        this.total = total;
    }

    @action
    setFlatList(flatList: FlatChat[]) {
        this.flatList = flatList;
    }

    @action
    setNewFilteredHouseIds(id: number) {
        if (this.filterByHouseId.includes(id)) {
            this.filterByHouseId = this.filterByHouseId.filter((i) => i !== id);
        } else {
            this.filterByHouseId.push(id);
        }
    }

    @computed
    flatChatLastMessagesFilterByHouseId() {
        if (this.filterByHouseId.length === 0) {
            if (this.searchCount.length === 0) {
                return this.flatChatLastMessages;
            } else {
                return this.flatChatLastMessages.filter((i) =>
                    i.flatName.includes(this.searchCount),
                );
            }
        } else {
            if (this.searchCount.length === 0) {
                return this.flatChatLastMessages.filter((i) =>
                    this.filterByHouseId.includes(i.houseId),
                );
            } else {
                return this.flatChatLastMessages
                    .filter((i) => this.filterByHouseId.includes(i.houseId))
                    .filter((i) => i.flatName.includes(this.searchCount));
            }
        }
    }

    @action
    openAddChatModal() {
        this.addChatModal = true;
    }

    @action
    closeChatModal() {
        this.addChatModal = false;
    }

    @action
    setSearchCount(text: string) {
        this.searchCount = text;
    }
}

const chatListStore = new ChatListStore();

export default chatListStore;
