import { action, makeAutoObservable } from 'mobx';
import { getLogs, getLogsForHouse } from 'services/logs';
import { LogItem } from 'services/logs/models';
import { SystemObjectType } from 'services/systemObjects/types';
import moment from 'moment/moment';

export class LogItemsModalStore {
    isOpen = false;
    systemObjectId: number;
    logs: LogItem[] = [];
    houseLogs: LogItem[] = [];
    currentType: SystemObjectType;
    loading = false;
    hasLoadedAtLeastOnce = false;
    pageNumber = 1;
    pageSize = 100;
    total = 0;
    sensorTypes: string[] = [];
    archiveTypes: string[] = [];
    success: boolean | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    openModal(systemObjectId: number, type: SystemObjectType) {
        this.isOpen = true;
        this.setSystemObjectId(systemObjectId, type);
    }

    @action
    closeModal() {
        this.isOpen = false;
        this.systemObjectId = undefined;
        this.setPageNumber(1);
        this.setTotal(0);
        this.setLoadedAtLeastOnce(false);
        this.setSuccessType(null);
        this.setSensorTypes([]);
        this.setArchiveTypes([]);
    }

    async getItems() {
        this.setLoading(true);
        const response = await getLogs({ systemObjectId: this.systemObjectId });
        this.setLogs(response.sensorPollingLogItems);
        this.setLoading(false);
    }

    @action
    resetLogs() {
        this.logs = [];
    }

    @action
    setLoading(v: boolean) {
        this.loading = v;
    }

    get hasMoreLogs() {
        return this.total >= this.pageNumber * this.pageSize;
    }

    async getItemsForHouse() {
        if (!this.systemObjectId) return;
        this.setLoading(true);
        this.setPageNumber(1);
        const response = await getLogsForHouse({
            houseId: this.systemObjectId,
            limit: this.pageSize,
            sensorMeasurementKindIds: this.sensorTypes,
            measurementSourceIds: this.archiveTypes,
            skip: (this.pageNumber - 1) * this.pageSize,
            success: this.success,
        });
        this.setHouseLogs(response.logItems.reverse());
        this.setTotal(this.total + response.logItems.length);
        this.setLoading(false);
        this.setLoadedAtLeastOnce(true);
        return {
            err: response.executionStatus !== 'Finished',
        };
    }

    async getLatestItemsForHouse(step) {
        if (!this.systemObjectId) return;
        this.setPageNumber(step);
        const response = await getLogsForHouse({
            houseId: this.systemObjectId,
            limit: this.pageSize,
            sensorMeasurementKindIds: this.sensorTypes,
            measurementSourceIds: this.archiveTypes,
            skip: (this.pageNumber - 1) * this.pageSize,
            success: this.success,
        });
        if (this.houseLogs.length > 0) {
            this.setHouseLogs([
                ...response.logItems
                    .slice(
                        response.logItems.findIndex((log) =>
                            moment(log.addedAt).isBefore(
                                moment(this.houseLogs[0].addedAt),
                            ),
                        ),
                    )
                    .reverse(),
                ...this.houseLogs,
            ]);
        } else {
            return;
        }
        this.setTotal(this.total + response.logItems.length);
        this.setLoadedAtLeastOnce(true);
        return {
            err: response.executionStatus !== 'Finished',
        };
    }

    async getNewItemsForHouse() {
        if (!this.systemObjectId) return;
        const response = await getLogsForHouse({
            houseId: this.systemObjectId,
            limit: this.pageSize,
            sensorMeasurementKindIds: this.sensorTypes,
            measurementSourceIds: this.archiveTypes,
            skip: 0,
            success: this.success,
        });
        if (this.houseLogs.length > 0) {
            const index = response.logItems
                .reverse()
                .findIndex((log) =>
                    moment(log.addedAt).isAfter(
                        moment(
                            this.houseLogs[this.houseLogs.length - 1].addedAt,
                        ),
                    ),
                );
            if (index >= 0) {
                this.setHouseLogs([
                    ...this.houseLogs,
                    ...response.logItems.slice(index),
                ]);
            } else {
                return;
            }
            return {
                err: response.executionStatus !== 'Finished',
            };
        }
    }

    @action
    setLogs(newLogs: LogItem[]) {
        this.logs = newLogs;
    }

    @action
    setHouseLogs(newLogs: LogItem[]) {
        this.houseLogs = newLogs;
    }

    @action
    setPageNumber(pageNumber: number) {
        this.pageNumber = pageNumber;
    }

    @action
    setTotal(total: number) {
        this.total = total;
    }

    @action
    setLoadedAtLeastOnce(value: boolean) {
        this.hasLoadedAtLeastOnce = value;
    }

    @action
    setArchiveTypes(types: string[]) {
        this.archiveTypes = types;
    }

    @action
    setSensorTypes(types: string[]) {
        this.sensorTypes = types;
    }

    @action
    setSuccessType(type: boolean | null) {
        this.success = type;
    }

    setSystemObjectId(systemObjectId: number, type: SystemObjectType) {
        this.systemObjectId = systemObjectId;
        this.currentType = type;
    }

    @action
    resetSystemObjectId() {
        this.systemObjectId = undefined;
    }
}

const logItemsModalStore = new LogItemsModalStore();

export default logItemsModalStore;
