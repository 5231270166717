import {
    GetAlertsBySystemObjectIdRequest,
    GetAlertsBySystemObjectIdResponse,
    GetAlertsRequest,
    GetAlertsResponse,
    SummaryGetAlertsByMeasurementTypeSummaryRequest,
    SummaryGetAlertsByTypeSummaryRequest,
    SummaryGetAllAlertsSummaryRequest,
    SummaryGetHousesSummaryRequest,
    SummaryGetOrgBranchesSummaryRequest,
    SummaryGetSummaryRequest,
    SummaryResponse,
} from './requestsResponses';
import { execute, generateClientSyncId } from '../../apiCommandsExecutor';
import { AlertsAPI } from './methods';
import {
    SummaryAlertByCommonTypeInfo,
    SummaryAlertByMeasurementTypeInfo,
    SummaryAlertInfo,
    SummaryData,
    SummaryHouseInfo,
    SummaryOrgBranchInfo,
} from './models';

export const getAlerts = async (params: GetAlertsRequest) => {
    const res = await execute<GetAlertsRequest, GetAlertsResponse>(
        AlertsAPI.getAlerts,
        {
            ...params,
            clientSyncId: generateClientSyncId(),
        },
    );
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAlertsBySystemObjectId = async (
    params: GetAlertsBySystemObjectIdRequest,
) => {
    const res = await execute<
        GetAlertsBySystemObjectIdRequest,
        GetAlertsBySystemObjectIdResponse
    >(AlertsAPI.getAlertsBySystemObjectId, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAlertsByMeasurementSummary = async (
    params: SummaryGetAlertsByMeasurementTypeSummaryRequest,
) => {
    const res = await execute<
        SummaryGetAlertsByMeasurementTypeSummaryRequest,
        SummaryResponse<SummaryAlertByMeasurementTypeInfo>
    >(AlertsAPI.SummaryGetAlertsByMeasurementTypeSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAlertsByTypeSummary = async (
    params: SummaryGetAlertsByTypeSummaryRequest,
) => {
    const res = await execute<
        SummaryGetAlertsByTypeSummaryRequest,
        SummaryResponse<SummaryAlertByCommonTypeInfo>
    >(AlertsAPI.SummaryGetAlertsByTypeSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getHousesSummary = async (
    params: SummaryGetHousesSummaryRequest,
) => {
    const res = await execute<
        SummaryGetHousesSummaryRequest,
        SummaryResponse<SummaryHouseInfo>
    >(AlertsAPI.SummaryGetHousesSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getOrgBranchSummary = async (
    params: SummaryGetOrgBranchesSummaryRequest,
) => {
    const res = await execute<
        SummaryGetOrgBranchesSummaryRequest,
        SummaryResponse<SummaryOrgBranchInfo>
    >(AlertsAPI.SummaryGetOrgBranchesSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSummary = async (params: SummaryGetSummaryRequest) => {
    const res = await execute<
        SummaryGetSummaryRequest,
        SummaryResponse<SummaryData>
    >(AlertsAPI.SummaryGetSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAllAlerts = async (
    params: SummaryGetAllAlertsSummaryRequest,
) => {
    const res = await execute<
        SummaryGetAllAlertsSummaryRequest,
        SummaryResponse<SummaryAlertInfo>
    >(AlertsAPI.SummaryGetAllAlertsSummary, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
