export interface GeneralRequest {
    clientSyncId?: string;
}

export enum ExecutionStatus {
    pending = 'Pending',
    finished = 'Finished',
    failed = 'Failed',
}

export interface GeneralResponse {
    clientSyncId: string;
    executionStatus: ExecutionStatus;
    message?: string;
}
