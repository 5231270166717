import React, { lazy } from 'react';
import { RouteInfoType, RouteType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import { AccountRole } from '../../services/accounts/types';

const OrganizationBranchesPage = lazy(
    () => import('./pages/organizationBranches'),
);

export const organizationBranchRoutes: RouteInfoType = {
    id: 'organizationBranch',
    name: 'ЖК',
    path: '/organization-branches',
    component: <LayoutWithMenu />,
    hide: true,
    routes: [
        {
            path: '/organization-branches',
            name: 'Жилые Комплексы',
            icon: <SidebarIcon type={SidebarIconType.houseBuildings} />,
            type: RouteType.route,
            component: <OrganizationBranchesPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Support,
            ],
        },
    ],
};

export default organizationBranchRoutes;
