import { execute } from '../../apiCommandsExecutor';
import {
    ChatsAddAccountToChatRequest,
    ChatsAddAccountToChatResponse,
    ChatsAddMessageToChatRequest,
    ChatsAddMessageToChatResponse,
    ChatsCreateChatRequest,
    ChatsCreateChatResponse,
    ChatsCreateFlatChatRequest,
    ChatsCreateFlatChatResponse,
    ChatsGetAccountChatsRequest,
    ChatsGetAccountChatsResponse,
    ChatsGetChatMessagesRequest,
    ChatsGetChatMessagesResponse,
    ChatsGetChatRequest,
    ChatsGetChatResponse,
    ChatsGetFlatChatListByHouseRequest,
    ChatsGetFlatChatListByHouseResponse,
    ChatsGetFlatChatListRequest,
    ChatsGetFlatChatListResponse,
    ChatsGetTechSupportChatListRequest,
    ChatsGetTechSupportChatListResponse,
    ChatsRemoveAccountFromChatRequest,
    ChatsRemoveAccountFromChatResponse,
    ChatsUpsertChatReadMarkRequest,
    ChatsUpsertChatReadMarkResponse,
} from './requestsResponses';
import { ChatAPI } from './methods';

export const createChat = async (params: ChatsCreateChatRequest) => {
    const res = await execute<ChatsCreateChatRequest, ChatsCreateChatResponse>(
        ChatAPI.ChatsCreateChat,
        { ...params },
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const addMessageToChat = async (
    params: ChatsAddMessageToChatRequest,
) => {
    const res = await execute<
        ChatsAddMessageToChatRequest,
        ChatsAddMessageToChatResponse
    >(ChatAPI.ChatsAddMessageToChat, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAccountChats = async (params: ChatsGetAccountChatsRequest) => {
    const res = await execute<
        ChatsGetAccountChatsRequest,
        ChatsGetAccountChatsResponse
    >(ChatAPI.ChatsGetAccountChats, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getChatMessages = async (params: ChatsGetChatMessagesRequest) => {
    const res = await execute<
        ChatsGetChatMessagesRequest,
        ChatsGetChatMessagesResponse
    >(ChatAPI.ChatsGetChatMessages, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const addAccountToChat = async (
    params: ChatsAddAccountToChatRequest,
) => {
    const res = await execute<
        ChatsAddAccountToChatRequest,
        ChatsAddAccountToChatResponse
    >(ChatAPI.ChatsAddAccountToChat, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const removeAccountFromChat = async (
    params: ChatsRemoveAccountFromChatRequest,
) => {
    const res = await execute<
        ChatsRemoveAccountFromChatRequest,
        ChatsRemoveAccountFromChatResponse
    >(ChatAPI.ChatsRemoveAccountFromChat, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const readChatMessages = async (
    params: ChatsUpsertChatReadMarkRequest,
) => {
    const res = await execute<
        ChatsUpsertChatReadMarkRequest,
        ChatsUpsertChatReadMarkResponse
    >(ChatAPI.ChatsUpsertChatReadMark, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getChatListByHouse = async (
    params: ChatsGetFlatChatListByHouseRequest,
) => {
    const res = await execute<
        ChatsGetFlatChatListByHouseRequest,
        ChatsGetFlatChatListByHouseResponse
    >(ChatAPI.ChatsGetFlatChatListByHouse, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createFlatChat = async (params: ChatsCreateFlatChatRequest) => {
    const res = await execute<
        ChatsCreateFlatChatRequest,
        ChatsCreateFlatChatResponse
    >(ChatAPI.ChatsCreateFlatChat, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getFlatChatList = async (params: ChatsGetFlatChatListRequest) => {
    const res = await execute<
        ChatsGetFlatChatListRequest,
        ChatsGetFlatChatListResponse
    >(ChatAPI.ChatsGetFlatChatList, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSupportChatList = async (
    params: ChatsGetTechSupportChatListRequest,
) => {
    const res = await execute<
        ChatsGetTechSupportChatListRequest,
        ChatsGetTechSupportChatListResponse
    >(ChatAPI.ChatsGetTechSupportChatList, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getChat = async (params: ChatsGetChatRequest) => {
    const res = await execute<ChatsGetChatRequest, ChatsGetChatResponse>(
        ChatAPI.ChatsGetChat,
        { ...params },
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
