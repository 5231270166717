import React from 'react';
import ErrorScreen from './ErrorScreen';

class ErrorBoundary extends React.Component<
    { children: any },
    { hasError: boolean; error: any }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    componentDidCatch(error: Error) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            this.setState({ hasError: true, error });
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        return this.state.hasError ? <ErrorScreen /> : this.props.children;
    }
}

export default ErrorBoundary;
