import React from 'react';
import { GlobalStyleProps } from '../types/types';
import styled, { createGlobalStyle } from 'styled-components';
import { CssBaseline, Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { SnackbarComponent } from '../common/components/Snackbars';
import AccountChangeModal from '../modules/auth/components/accountChangeModal';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background: ${(props) => props.theme.body.background};
    }


    .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
    }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};
    box-shadow: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

type DashboardPropsType = {
    //route: any;
    //width: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

const ConciergeLayout: React.FC<DashboardPropsType> = () => {
    // const [mobileOpen, setMobileOpen] = useState(false);
    // const handleDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen);
    // };

    return (
        <Root>
            <SnackbarComponent />
            <AccountChangeModal />
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
                <MainContent>
                    <React.Suspense fallback={<>...</>}>
                        <Outlet />
                    </React.Suspense>
                </MainContent>
            </AppContent>
        </Root>
    );
};

export default ConciergeLayout;
