import { execute } from '../../apiCommandsExecutor';
import { PaymentProvidersAPI } from './methods';
import {
    PaymentProviderCreateTKassaProviderRequest,
    PaymentProviderCreateTKassaProviderResponse,
    PaymentProviderCreateYooKassaProviderRequest,
    PaymentProviderCreateYooKassaProviderResponse,
    PaymentProviderRemovePaymentProviderRequest,
    PaymentProviderRemovePaymentProviderResponse,
    PaymentProvidersGetProvidersRequest,
    PaymentProvidersGetProvidersResponse,
    PaymentProviderUpdateTKassaProviderRequest,
    PaymentProviderUpdateTKassaProviderResponse,
    PaymentProviderUpdateYooKassaProviderRequest,
    PaymentProviderUpdateYooKassaProviderResponse,
} from './requestsResponses';

export const getPaymentProviders = async (
    params: PaymentProvidersGetProvidersRequest,
) => {
    const res = await execute<
        PaymentProvidersGetProvidersRequest,
        PaymentProvidersGetProvidersResponse
    >(PaymentProvidersAPI.PaymentProvidersGetProviders, { ...params });
    return res.data;
};

export const createYooKassaProvider = async (
    params: PaymentProviderCreateYooKassaProviderRequest,
) => {
    const res = await execute<
        PaymentProviderCreateYooKassaProviderRequest,
        PaymentProviderCreateYooKassaProviderResponse
    >(PaymentProvidersAPI.PaymentProviderCreateYooKassaProvider, { ...params });
    return res.data;
};

export const createTKassaProvider = async (
    params: PaymentProviderCreateTKassaProviderRequest,
) => {
    const res = await execute<
        PaymentProviderCreateTKassaProviderRequest,
        PaymentProviderCreateTKassaProviderResponse
    >(PaymentProvidersAPI.PaymentProviderCreateTKassaProvider, { ...params });
    return res.data;
};

export const updateYooKassaProvider = async (
    params: PaymentProviderUpdateYooKassaProviderRequest,
) => {
    const res = await execute<
        PaymentProviderUpdateYooKassaProviderRequest,
        PaymentProviderUpdateYooKassaProviderResponse
    >(PaymentProvidersAPI.PaymentProviderUpdateYooKassaProvider, { ...params });
    return res.data;
};

export const updateTKassaProvider = async (
    params: PaymentProviderUpdateTKassaProviderRequest,
) => {
    const res = await execute<
        PaymentProviderUpdateTKassaProviderRequest,
        PaymentProviderUpdateTKassaProviderResponse
    >(PaymentProvidersAPI.PaymentProviderUpdateTKassaProvider, { ...params });
    return res.data;
};

export const removePaymentProvider = async (
    params: PaymentProviderRemovePaymentProviderRequest,
) => {
    const res = await execute<
        PaymentProviderRemovePaymentProviderRequest,
        PaymentProviderRemovePaymentProviderResponse
    >(PaymentProvidersAPI.PaymentProviderRemovePaymentProvider, { ...params });
    return res.data;
};
