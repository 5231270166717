import { makeAutoObservable } from 'mobx';
import GeneralStore from '../common/store';
import AuthStore from '../stores/auth';
import AccountsStore from './accounts';
import SystemObjectsStore from '../stores/systemObjects';
import ReportsStore from './reports';
import ConstantsStore from './constants';
import AlertsStore from './alerts';
import OrganizationBranchesStore from './organizationBranches';

const generalStore = new GeneralStore();
const authStore = new AuthStore();
const accountsStore = new AccountsStore();
const systemObjectsStore = new SystemObjectsStore();
const reportsStore = new ReportsStore();
const constantsStore = new ConstantsStore();
const alertsStore = new AlertsStore();
const organizationBranchesStore = new OrganizationBranchesStore();

interface IStores {
    generalStore;
    authStore;
    accountsStore;
    systemObjectsStore;
    reportsStore;
    constantsStore;
    alertsStore;
    organizationBranchesStore;
}

export class RootStore {
    generalStore: GeneralStore;
    authStore: AuthStore;
    accountsStore: AccountsStore;
    systemObjectsStore: SystemObjectsStore;
    reportsStore: ReportsStore;
    alertsStore: AlertsStore;
    constantsStore: ConstantsStore;
    organizationBranchesStore: OrganizationBranchesStore;

    constructor(stores: IStores) {
        makeAutoObservable(this);
        this.generalStore = stores.generalStore;
        this.authStore = stores.authStore;
        this.accountsStore = stores.accountsStore;
        this.systemObjectsStore = stores.systemObjectsStore;
        this.reportsStore = stores.reportsStore;
        this.constantsStore = stores.constantsStore;
        this.alertsStore = stores.alertsStore;
        this.organizationBranchesStore = stores.organizationBranchesStore;
    }
}

const stores = {
    generalStore,
    authStore,
    accountsStore,
    systemObjectsStore,
    reportsStore,
    constantsStore,
    alertsStore,
    organizationBranchesStore,
};

const rootStore = new RootStore(stores);
export default rootStore;
