import React, { useContext } from 'react';
import { Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import TelephonyStoreContext from '../store/context';
import { useNavigate } from 'react-router-dom';

export const IncomingCallSnackbar = observer(() => {
    const telephonyStore = useContext(TelephonyStoreContext);
    const navigate = useNavigate();

    return (
        <Snackbar
            open={telephonyStore.incomingCallSnackbar}
            onClose={() => telephonyStore.closeIncomingCallSnackbar()}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                navigate(`/maintainer/crm/pre-requests`);
                telephonyStore.closeIncomingCallSnackbar();
            }}
            style={{ cursor: 'pointer' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            message={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <span
                            style={{ whiteSpace: 'pre-line' }}
                            id='message-id'
                        >
                            {'Входящий звонок'}
                        </span>
                        <span
                            style={{ whiteSpace: 'pre-line', color: '#ff6831' }}
                            id='message-id'
                        >
                            {' "MANGO OFFICE"'}
                        </span>
                    </div>
                    {telephonyStore.wsCallerInfo?.callerPhoneNumber ? (
                        <span
                            style={{ whiteSpace: 'pre-line' }}
                            id='message-id'
                        >
                            {`C номера: ${telephonyStore.wsCallerInfo.callerPhoneNumber}`}
                        </span>
                    ) : null}
                    <span
                        style={{
                            whiteSpace: 'pre-line',
                            color: telephonyStore.wsCallerInfo?.callerDetails
                                ?.callerName
                                ? '#6ED278'
                                : '#C40000',
                        }}
                        id='message-id'
                    >
                        {telephonyStore.wsCallerInfo?.callerDetails?.callerName
                            ? 'Житель определён'
                            : 'Не удалось распознать жителя'}
                    </span>
                    {telephonyStore.wsCallerInfo?.callerDetails?.callerName ? (
                        <span
                            style={{
                                whiteSpace: 'pre-line',
                            }}
                            id='message-id'
                        >
                            {`ФИО: ${telephonyStore.wsCallerInfo?.callerDetails?.callerName}`}
                        </span>
                    ) : null}
                    {telephonyStore.wsCallerInfo?.callerDetails?.flatElementList
                        ?.length ? (
                        <span
                            style={{
                                whiteSpace: 'pre-line',
                            }}
                            id='message-id'
                        >
                            {`Адрес: ${telephonyStore.wsCallerInfo?.callerDetails?.flatElementList[0]?.orgBranchName}, ${telephonyStore.wsCallerInfo?.callerDetails.flatElementList[0]?.houseAddress}, кв: ${telephonyStore.wsCallerInfo?.callerDetails?.flatElementList[0]?.flatNumber}`}
                        </span>
                    ) : null}
                </div>
            }
        />
    );
});
