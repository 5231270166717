import { action, computed, makeAutoObservable } from 'mobx';

import {
    AccountClientModel,
    ICreateAccountParams,
    IUpdateAccountParams,
} from '../services/accounts/models';

import { AccountPermissionsUpdatePermissionRequest } from '../services/accounts/requestsResponses';
import { AccountRole } from '../services/accounts/types';
import {
    createAccount,
    getAccounts,
    removeAccount,
    updateAccount,
    updatePermissions,
} from '../services/accounts';
import { ExecutionStatus } from '../apiCommandsExecutor/api';

class AccountsStore {
    list: AccountClientModel[] = [];
    current?: AccountClientModel;
    isOpen = false;
    loading = true;
    removeModal = false;
    removedAccount: AccountClientModel;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setIsLoading(v: boolean) {
        this.loading = v;
    }

    async getAccounts(value: number) {
        this.setIsLoading(true);
        const render = (value: number) => {
            switch (value) {
                case 0:
                    return [
                        AccountRole.Admin,
                        AccountRole.Maintainer,
                        AccountRole.Manager,
                        AccountRole.Support,
                        AccountRole.TechnicalSupport,
                        AccountRole.Concierge,
                        AccountRole.SuperUser,
                    ];
                case 1:
                    return [AccountRole.Service];
                case 2:
                    return [AccountRole.Integration];
            }
        };
        const response = await getAccounts({ accountRoles: render(value) });
        if (!response) {
            // set error
            this.setIsLoading(false);
            return;
        }
        this.saveAccounts(response.accounts);
        this.setIsLoading(false);
    }

    @action
    openForm(current?: AccountClientModel) {
        this.current = current;
        this.isOpen = true;
    }

    @action
    closeForm() {
        this.current = undefined;
        this.isOpen = false;
    }

    @action
    openRemoveModal(account: AccountClientModel) {
        this.removedAccount = account;
        this.removeModal = true;
    }

    @action
    closeRemoveModal() {
        this.removedAccount = undefined;
        this.removeModal = false;
    }

    @action
    saveAccounts(accounts: AccountClientModel[]) {
        this.list = accounts;
    }

    async createAccount(params: ICreateAccountParams) {
        const t = await createAccount(params);
        if (t.executionStatus !== ExecutionStatus.finished) {
            return { res: false, description: t.message };
        } else {
            return { res: true };
        }
    }

    async updateAccount(params: IUpdateAccountParams) {
        const t = await updateAccount(params);
        if (t.executionStatus !== ExecutionStatus.finished) {
            return { res2: false, description: t.message };
        } else {
            return { res2: true };
        }
    }

    async removeAccount(accountId: number) {
        const t = await removeAccount({
            accountId,
        });
        if (t.executionStatus !== ExecutionStatus.finished) {
            return { res: false, description: t.message };
        } else {
            return { res: true };
        }
    }

    // @computed
    // getServiceAccounts() {
    //     return this.list.filter((item) => item.role === AccountRole.Service);
    // }
    //
    // @computed
    // getIntegrationAccounts() {
    //     return this.list.filter(
    //         (item) => item.role === AccountRole.Integration,
    //     );
    // }

    @computed
    getOrdinaryAccounts() {
        return this.list.filter(
            (item) =>
                item.role !== AccountRole.HouseResident &&
                item.role !== AccountRole.Service &&
                item.role !== AccountRole.Integration,
        );
    }

    async updatePermissions(params: AccountPermissionsUpdatePermissionRequest) {
        const t = await updatePermissions(params);
        if (t.executionStatus !== ExecutionStatus.finished) {
            return { res1: false, description: t.message };
        } else {
            return { res1: true };
        }
    }
}

export default AccountsStore;
