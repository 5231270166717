import { computed, makeAutoObservable, runInAction } from 'mobx';
import { AlertStatus, AlertType } from '../services/constantsRegistry/models';
import { getAlertStatuses, getAlertTypes } from '../services/constantsRegistry';
import { locale } from '../common/components/libs';

class ConstantsStore {
    alertTypes: { [k: string]: AlertType } = {};
    alertStatuses: { [k: string]: AlertStatus } = {};
    isReady = false;
    isLoading = false;

    dict = {
        Heat: 'Тепло',
        ElectricEnergy: 'Электроэнергия',
        ColdWater: 'ХВС',
        HotWater: 'ГВС',
        CleanWater: 'Питьевая вода',
        Gas: 'Газ',
        Security: 'Безопасность',
    };

    constructor() {
        makeAutoObservable(this);
    }

    async init() {
        runInAction(() => {
            this.isLoading = true;
        });
        const typesResponse = await getAlertTypes();
        const statusesResponse = await getAlertStatuses();
        runInAction(() => {
            this.alertStatuses = statusesResponse.statuses.reduce(
                (memo, item) => {
                    memo[item.id] = item;
                    return memo;
                },
                {},
            );
            this.alertTypes = typesResponse.types.reduce((memo, item) => {
                memo[item.id] = item;
                return memo;
            }, {});
            this.isReady = true;
            this.isLoading = true;
        });
    }

    ensureIsLoaded() {
        if (!this.isReady && !this.isLoading) this.init();
    }

    @computed
    getAlertTypeById(id: string) {
        return this.alertTypes[id];
    }

    @computed
    getAlertStatusById(id: string) {
        return this.alertStatuses[id];
    }

    formatType(typeId: string) {
        return this.alertTypes[typeId]?.title[locale];
    }

    formatStatus(statusId: string) {
        return this.alertStatuses[statusId]?.title[locale];
    }

    EN2RU(str: string) {
        return this.dict[str] || str;
    }
}

export default ConstantsStore;
