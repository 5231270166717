import React from 'react';

import { Helmet } from 'react-helmet';
import {
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import './style.css';

import { ThemeProvider } from 'styled-components';

import maTheme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ru } from 'date-fns/locale/ru';

import {
    CategoryScale,
    Chart,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
} from 'chart.js';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Filler);

function App(router: any) {
    return (
        <React.Fragment>
            <Helmet
                titleTemplate='%s'
                defaultTitle='Wise - экосистема для управляющей компании'
            />
            <StyledEngineProvider injectFirst>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ru}
                >
                    <MuiThemeProvider theme={maTheme[0]}>
                        <ThemeProvider theme={maTheme[0]}>
                            {router.children}
                        </ThemeProvider>
                    </MuiThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );
}

export default App;
