import styled from 'styled-components';
import { Alert as MuiAlert, AlertTitle } from '@mui/lab';

import {
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    CircularProgress as MuiCircularProgress,
    FormControl as MuiFormControl,
    FormControlLabel as MuiFormControlLabel,
    Paper as MuiPaper,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from '@mui/material';

import { green, orange, red } from '@mui/material/colors';
import React from 'react';
import { HubState } from '../../modules/maintainer/pages/systemObjectsPage/forms/EditHubSystemObjectForm';
import {
    ConnectionLineSettings,
    HubSettings,
} from '../../services/systemObjects/types';
import { ConnectionLineState } from '../../modules/maintainer/pages/systemObjectsPage/forms/EditConnectionLineSystemObjectForm';
import { spacing, SpacingProps } from '@mui/system';
import { Edit } from 'react-feather';

export const Card = styled(MuiCard)(spacing);

export const CardContent = styled(MuiCardContent)`
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

interface TypographyPropsType extends SpacingProps {
    component?: string;
}

export const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

export const Paper = styled(MuiPaper)(spacing);

export interface ChipPropstype extends SpacingProps {
    shipped?: boolean;
    processing?: boolean;
    cancelled?: boolean;
}

export const Chip = styled(MuiChip)<ChipPropstype>`
    ${spacing};

    background: ${(props) => props.shipped && green[500]};
    background: ${(props) => props.processing && orange[700]};
    background: ${(props) => props.cancelled && red[500]};
    color: ${(props) => props.theme.palette.common.white};
`;

export const Spacer = styled.div(spacing);

export const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

export const Button = styled(MuiButton)(spacing);

export const CircularProgress = styled(MuiCircularProgress)(spacing);

export const FormControl = styled(MuiFormControl)<SpacingProps>(spacing);
export const FormControlLabel =
    styled(MuiFormControlLabel)<SpacingProps>(spacing);

export const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export const locale = 'ru';

const Alert = styled(MuiAlert)(spacing);

interface IAlertProps {
    title: string;
    content: JSX.Element | string;
}

export const ErrorAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='error'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const WarningAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='warning'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const InfoAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='info'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const SuccessAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='success'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const FlexRowSpaceBetween = (props: { children: any }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {props.children}
        </div>
    );
};

export const FlexRowSpaceAround = (props: { children: any }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {props.children}
        </div>
    );
};

export const FlexRow = (props: { children: any }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {props.children}
        </div>
    );
};

export const changeEventHandlerDecorator = (fn: (value: any) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
        fn(e.target.value);
    };
};

export const initStateForHub = (
    state: HubState,
    settings: HubSettings,
    name: string,
) => {
    const newState = { ...state };
    newState.name = name;
    Object.keys(settings).forEach((item) => {
        if (item in newState) {
            newState[item] = settings[item];
        }
    });
    return newState;
};

export const initStateForConnectionLine = (
    state: ConnectionLineState,
    settings: ConnectionLineSettings,
    name: string,
) => {
    const newState = { ...state };
    newState.name = name;
    Object.keys(settings).forEach((item) => {
        if (item in newState) {
            newState[item] = settings[item];
        }
    });
    return newState;
};

export const TextWithLabel = (props: { text: string; label: string }) => {
    return (
        <div>
            <p
                style={{
                    fontSize: 13,
                    color: '#8A8A8A',
                    marginBottom: 0,
                }}
            >
                {props.label}
            </p>
            <Typography variant={'body2'}>{props.text}</Typography>
        </div>
    );
};

export const TitleWithLabel = (props: { text: string; label: string }) => {
    return (
        <div>
            <p
                style={{
                    fontSize: 14,
                    color: '#8A8A8A',
                    marginBottom: 0,
                }}
            >
                {props.label}
            </p>
            <p
                style={{
                    fontSize: 15,
                    color: '#1a1a1a',
                    marginBottom: 0,
                    marginTop: 0,
                    fontWeight: 500,
                }}
            >
                {props.text}
            </p>
        </div>
    );
};

export const EditButton = (props: { onClick: () => void }) => {
    return (
        <Button color='primary' onClick={props.onClick}>
            <Edit style={{ width: '20px', height: '20px' }} />
            <span
                style={{
                    marginLeft: '3px',
                    fontSize: 'small',
                }}
            >
                Редактировать
            </span>
        </Button>
    );
};
