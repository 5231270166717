import React, { FC, LazyExoticComponent } from 'react';
import { ThemeProps } from 'styled-components';
import { Theme } from '@mui/material';
import { SpacingProps } from '@mui/system';
import { AccountRole } from '../services/accounts/types';

export type GlobalStyleProps = {
    theme: ThemeProps<Theme> & { body: any };
};

export interface MuiButtonSpacingType extends SpacingProps {
    component?: React.PropsWithoutRef<{}>;
    to?: string;
}

export interface MuiChipSpacingType extends SpacingProps {
    component?: React.PropsWithoutRef<{}>;
    href?: string;
    icon?: JSX.Element | null;
}

// Routes
export type RouteInfoType = {
    id?: string;
    name: string;
    path: string;
    icon?: React.ReactElement;
    routes?: Array<RouteInfoType>;
    component: React.ReactNode | LazyExoticComponent<FC<{}>> | null;
    badge?: string | number;
    header?: string;
    hide?: boolean;
    roles?: AccountRole[];
    type?: RouteType;
    redirect?: string;
    index?: boolean;
    module?: RouteModuleType;
    childrenModule?: RouteModuleType[];
};

export enum RouteType {
    section = 'section',
    route = 'route',
}

export enum RouteModuleType {
    askue = 'askue',
    cleanWaterManagement = 'cleanWaterManagement',
    crm = 'crm',
    showFireDetection = 'showFireDetection',
    showLeakDetection = 'showLeakDetection',
    showSecurityDetection = 'showSecurityDetection',
    showManagementCompanyInfo = 'showManagementCompanyInfo',
    invoices = 'invoices',
    iptelecom = 'iptelecom',
    newsAndVotings = 'newsAndVotings',
    paidServices = 'paidServices',
    skud = 'skud',
    smartHome = 'smartHome',
    video = 'video',
    chat = 'chatWithManagementCompanyEnabled',
}

/*export type ChildElement =
    | {
          path: string;
          name: string;
          component: any;
          icon?: JSX.Element;
          badge?: string | number;
          roles?: AccountRole[];
          type: ChildElementType.route;
          redirect?: string;
          index?: boolean;
      }
    | {
          header: string;
          type: ChildElementType.section;
      };*/

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
