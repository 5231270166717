import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    getConsolidateHouseConsumptionReport,
    getConsumptionReport,
} from '../services/reports';
import {
    ConsumptionReport,
    HouseMeasurementsReport,
} from '../services/reports/models';
import moment from 'moment/moment';

interface IReportConfiguration {
    fromDate: Date;
    toDate: Date;
    sensorMeasurementKind: string;
    systemObjectId: number;
    extended: boolean;
}

export enum ConsumptionReportType {
    default = 'По ресурсу',
    consolidate = 'Сводный',
    sensor = 'По счетчику',
}

class ReportsStore {
    configuration: IReportConfiguration = {
        fromDate: moment().subtract(7, 'days').startOf('day').toDate(),
        toDate: moment().endOf('day').toDate(),
        sensorMeasurementKind: null,
        systemObjectId: null,
        extended: false,
    };

    organizationId: number;
    type: ConsumptionReportType = ConsumptionReportType.default;
    report?: ConsumptionReport;
    houseMeasurementsReport?: HouseMeasurementsReport;
    isLoading = false;
    downloadModal = false;
    downloadConfiguration: IReportConfiguration = {
        fromDate: moment().subtract(7, 'days').startOf('day').toDate(),
        toDate: moment().endOf('day').toDate(),
        sensorMeasurementKind: null,
        systemObjectId: null,
        extended: false,
    };
    downloadReportType: ConsumptionReportType;
    typesForConsolidateReport = [
        'Heat',
        'ColdWater',
        'HotWater',
        'ElectricEnergy',
    ];
    selectedTypesForConsolidateReport = [
        'Heat',
        'ColdWater',
        'HotWater',
        'ElectricEnergy',
    ];
    downloadReportSensorModelIds = [
        'VzletTCP026',
        'VzletTCP043',
        'Termotronik_TV7',
    ];
    sensorsForReports: any[] = [];
    downloadReportSensor: any;
    groupByTariffs = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setOrganizationId(id: number) {
        this.organizationId = id;
        this.setSystemObject(null);
        this.setSensorMeasurement(null);
        this.setReport(null);
        this.setConsolidateReport(null);
        id === 21
            ? this.setReportType(ConsumptionReportType.consolidate)
            : this.setReportType(ConsumptionReportType.default);
    }

    @action
    setFrom(from: Date) {
        this.configuration.fromDate = from;
        this.type === ConsumptionReportType.default
            ? this.loadReportValues()
            : this.loadConsolidateHouseValues();
    }

    @action
    setTo(to: Date) {
        this.configuration.toDate = to;
        this.type === ConsumptionReportType.default
            ? this.loadReportValues()
            : this.loadConsolidateHouseValues();
    }

    @action
    setFromDownload(from: Date) {
        this.downloadConfiguration.fromDate = from;
    }

    @action
    setToDownload(to: Date) {
        this.downloadConfiguration.toDate = to;
    }

    @action
    setSystemObject(systemObjectId: number) {
        this.configuration.systemObjectId = systemObjectId;
        this.configuration.extended = false;
        this.loadReportValues();
        this.type === ConsumptionReportType.default
            ? this.loadReportValues()
            : this.loadConsolidateHouseValues();
    }

    @action
    setSystemObjectDR(systemObjectId: number) {
        this.downloadConfiguration.systemObjectId = systemObjectId;
        this.downloadConfiguration.extended = false;
    }

    @action
    setReportType(type: ConsumptionReportType) {
        this.type = type;
        this.type === ConsumptionReportType.default
            ? this.loadReportValues()
            : this.loadConsolidateHouseValues();
    }

    @action
    setReportTypeDR(type: ConsumptionReportType) {
        this.downloadReportType = type;
    }

    @action
    setSensorMeasurement(value: string) {
        this.configuration.sensorMeasurementKind = value;
        this.configuration.extended = false;
        this.loadReportValues();
    }

    @action
    setSensorMeasurementDR(value: string) {
        this.downloadConfiguration.sensorMeasurementKind = value;
    }

    @action
    setReport(report: ConsumptionReport) {
        this.report = report;
    }

    @action
    setSensorsForReport(report: ConsumptionReport) {
        this.sensorsForReports =
            report.data.rows
                .filter((i) =>
                    this.downloadReportSensorModelIds.includes(
                        i.metadata.sensorModelId,
                    ),
                )
                .map((i) => {
                    return {
                        flatId: i.metadata.flatId,
                        flatName: i.values[0],
                        sensorModelId: i.metadata.sensorModelId,
                        sensorId: i.metadata.sensorSystemObjectId,
                        sensorSerialNumber: i.values[1],
                    };
                }) || [];
    }

    @action
    setSensorsForConsolidateReport(report: HouseMeasurementsReport) {
        this.sensorsForReports =
            report.data.rows
                .filter((i) =>
                    this.downloadReportSensorModelIds.includes(
                        i.metadata.sensorModelId,
                    ),
                )
                .map((i) => {
                    return {
                        flatId: i.metadata.flatId,
                        flatName: i.metadata.flatName,
                        sensorModelId: i.metadata.sensorModelId,
                        sensorId: i.metadata.sensorId,
                        sensorSerialNumber: i.metadata.sensorSerialNumber,
                    };
                }) || [];
    }

    @action
    setConsolidateReport(report: HouseMeasurementsReport) {
        this.houseMeasurementsReport = report;
    }

    @action
    toggleExtended() {
        this.configuration.extended = !this.configuration.extended;
        this.loadReportValues();
    }

    @action
    setDownloadReportExtended() {
        this.downloadConfiguration.extended =
            !this.downloadConfiguration.extended;
    }

    @action
    setDownloadReportGroup() {
        this.groupByTariffs = !this.groupByTariffs;
    }

    async loadReportValues() {
        const configuration = this.configuration;
        if (
            !configuration.fromDate ||
            !configuration.toDate ||
            !configuration.sensorMeasurementKind ||
            !configuration.systemObjectId
        ) {
            return;
        }

        runInAction(() => {
            this.isLoading = true;
        });

        const result = await getConsumptionReport(configuration);
        if (!result || !result.consumptionReport) {
            runInAction(() => {
                this.isLoading = false;
            });
            return;
        }
        this.setReport(result.consumptionReport);
        this.setSensorsForReport(result.consumptionReport);
        runInAction(() => {
            this.isLoading = false;
        });
    }

    async loadConsolidateHouseValues() {
        const configuration = this.configuration;
        if (
            !configuration.fromDate ||
            !configuration.toDate ||
            !configuration.systemObjectId
        ) {
            return;
        }

        runInAction(() => {
            this.isLoading = true;
        });

        const result = await getConsolidateHouseConsumptionReport({
            fromDate: configuration.fromDate,
            toDate: configuration.toDate,
            houseSystemObjectId: configuration.systemObjectId,
        });
        this.setConsolidateReport(result.houseMeasurementsReport);
        this.setSensorsForConsolidateReport(result.houseMeasurementsReport);
        runInAction(() => {
            this.isLoading = false;
        });
    }

    @action
    openDownloadModal() {
        this.downloadModal = true;
        this.groupByTariffs = false;
        this.downloadConfiguration.fromDate = this.configuration.fromDate;
        this.downloadConfiguration.toDate = this.configuration.toDate;
        this.downloadConfiguration.systemObjectId =
            this.configuration.systemObjectId;
        this.downloadReportType = this.type;
        this.downloadConfiguration.sensorMeasurementKind =
            this.configuration.sensorMeasurementKind;
    }

    @action
    closeDownloadModal() {
        this.downloadModal = false;
        this.downloadConfiguration.fromDate = moment()
            .subtract(7, 'days')
            .startOf('day')
            .toDate();
        this.downloadConfiguration.toDate = moment().endOf('day').toDate();
        this.downloadConfiguration.systemObjectId = null;
        this.downloadReportType = undefined;
        this.downloadConfiguration.sensorMeasurementKind = null;
        this.downloadConfiguration.extended = false;
        this.selectedTypesForConsolidateReport = this.typesForConsolidateReport;
        this.sensorsForReports = [];
        this.setDownloadReportSensor(undefined);
    }

    @action
    selectReportType(types: string[]) {
        this.selectedTypesForConsolidateReport = types;
    }

    @action
    setDownloadReportSensor(sensor: any) {
        this.downloadReportSensor = sensor;
    }
}

export default ReportsStore;
