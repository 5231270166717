import { execute } from '../../apiCommandsExecutor';
import { ReportsAPI } from './methods';
import { IGetConsumptionReportParams } from './models';
import {
    ReportsGetConsolidateHouseConsumptionReportRequest,
    ReportsGetConsolidateHouseConsumptionReportResponse,
    ReportsGetConsumptionReportRequest,
    ReportsGetConsumptionReportResponse,
} from './requestsResponses';

export const getConsumptionReport = async (
    params: IGetConsumptionReportParams,
) => {
    const res = await execute<
        ReportsGetConsumptionReportRequest,
        ReportsGetConsumptionReportResponse
    >(ReportsAPI.ConsumptionReportsGetConsumptionReport, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getConsolidateHouseConsumptionReport = async (
    params: ReportsGetConsolidateHouseConsumptionReportRequest,
) => {
    const res = await execute<
        ReportsGetConsolidateHouseConsumptionReportRequest,
        ReportsGetConsolidateHouseConsumptionReportResponse
    >(ReportsAPI.ConsumptionReportsGetConsolidateHouseConsumptionReport, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
