export enum AlertsAPI {
    getAlerts = 'Alerts.GetAlerts',
    getAlertsBySystemObjectId = 'Alerts.GetAlertsBySystemObjectId',
    SummaryGetAlertsByMeasurementTypeSummary = 'Summary.GetAlertsByMeasurementTypeSummary',
    SummaryGetAlertsByTypeSummary = 'Summary.GetAlertsByTypeSummary',
    SummaryGetHousesSummary = 'Summary.GetHousesSummary',
    SummaryGetOrgBranchesSummary = 'Summary.GetOrgBranchesSummary',
    SummaryGetSummary = 'Summary.GetSummary',
    SummaryGetAllAlertsSummary = 'Summary.GetAllAlertsSummary',
}
