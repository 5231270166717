export enum SystemObjectsAPI {
    SystemObjectsCreateConnectionLine = 'SystemObjects.CreateConnectionLine',
    SystemObjectsGenerateHouse = 'SystemObjects.GenerateHouse',
    SystemObjectsCreateFlat = 'SystemObjects.CreateFlat',
    SystemObjectsCreateHouse = 'SystemObjects.CreateHouse',
    SystemObjectsCreateHub = 'SystemObjects.CreateHub',
    SystemObjectsCreateSensor = 'SystemObjects.CreateSensor',
    SystemObjectsCreateSensorPlaceholder = 'SystemObjects.CreateSensorPlaceholder',
    SystemObjectsGetSystemObjects = 'SystemObjects.GetSystemObjects',
    SystemObjectsUpdateConnectionLine = 'SystemObjects.UpdateConnectionLine',
    SystemObjectGetSystemObjectById = 'SystemObjects.GetSystemObjectById',
    SystemObjectsUpdateFlat = 'SystemObjects.UpdateFlat',
    SystemObjectsUpdateHouse = 'SystemObjects.UpdateHouse',
    SystemObjectsUpdateHub = 'SystemObjects.UpdateHub',
    SystemObjectsUpdateSensor = 'SystemObjects.UpdateSensor',
    SystemObjectsUpdateSensorPlaceholder = 'SystemObjects.UpdateSensorPlaceholder',
    SystemObjectsRemoveHouse = 'SystemObjects.RemoveHouse',
    SystemObjectsMoveHouse = 'SystemObjects.MoveHouse',
    SystemObjectsRemoveSystemObject = 'SystemObjects.RemoveSystemObject',
    SystemObjectsUpdatePollingConfig = 'SystemObjects.UpdatePollingConfig',
    SystemObjectsCreatePollingGroup = 'SystemObjects.CreatePollingGroup',
    HousesUpdateNotificationSettings = 'Houses.UpdateNotificationSettings',
    SystemObjectsUpdateManualSendingNotificationsEnabledInHouse = 'SystemObjects.UpdateManualSendingNotificationsEnabledInHouse',
    SystemObjectsCreateCopy = 'SystemObjects.CreateCopy',
    FlatsPushSensorMeasurementsManually = 'Flats.PushSensorMeasurementsManually',
    SystemObjectsUpdateExternalIdent = 'SystemObjects.UpdateExternalIdent',
    HousesUpdateHouseResidentSignUpConfig = 'Houses.UpdateHouseResidentSignUpConfig',
}
