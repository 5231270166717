import { SensorType } from '../alerts/types';

export enum SecurityStatus {
    ok = 'OK',
    notOk = 'NOT_OK',
    unknown = 'UNKNOWN',
}

export enum FireDetectionStatus {
    ok = 'OK',
    notOk = 'NOT_OK',
    unknown = 'UNKNOWN',
}

export enum LeakDetectionStatus {
    ok = 'OK',
    notOk = 'NOT_OK',
    unknown = 'UNKNOWN',
}

export enum ArchiveType {
    monthly = 'ArchiveMonthly',
    daily = 'ArchiveDaily',
    hourly = 'ArchiveHourly',
    current = 'CurrentValues',
}

export type ArchivesType =
    | 'CurrentValues'
    | 'ArchiveDaily'
    | 'ArchiveHourly'
    | 'ArchiveMonthly';

export const ArchiveTypes: { [key in ArchivesType]?: string } = {
    CurrentValues: 'Текущие значения',
    ArchiveHourly: 'Часовые архивы',
    ArchiveDaily: 'Дневные архивы',
    ArchiveMonthly: 'Месячные архивы',
};

export interface UnifiedFormatPressure {
    startPeriodDate: string;
    measurementType: SensorType.pressure;
    pressure: number;
}

export interface UnifiedFormatTemperature {
    startPeriodDate: string;
    measurementType: SensorType.temperature;
    temperature: number;
}

export interface UnifiedFormatHeat {
    startPeriodDate: string;
    measurementType: SensorType.heat;
    volume: number;
    tIn: number;
    tOut: number;
    energy: number;
}

export interface UnifiedFormatWater {
    startPeriodDate: string;
    measurementType:
        | SensorType.coldWater
        | SensorType.hotWater
        | SensorType.cleanWater;
    volume: number;
}

export interface UnifiedFormatGas {
    startPeriodDate: string;
    measurementType: SensorType.gas;
    volume: number;
}

export interface UnifiedFormatSecurity {
    startPeriodDate: string;
    measurementType: SensorType.security;
    status: SecurityStatus;
}

export interface UnifiedFormatElectricity {
    startPeriodDate: string;
    measurementType: SensorType.electricEnergy;
    t1: number;
    t2: number;
    energySum: number;
}

export interface UnifiedFormatLeakDetection {
    startPeriodDate: string;
    measurementType: SensorType.leakDetection;
    status: LeakDetectionStatus;
}

export interface UnifiedFormatFireDetection {
    startPeriodDate: string;
    measurementType: SensorType.fireDetection;
    status: FireDetectionStatus;
}

export interface UnifiedFormatNone {
    startPeriodDate: string;
    measurementType: SensorType.none;
    message: string;
}

export interface UnifiedFormatPressure {
    startPeriodDate: string;
    measurementType: SensorType.pressure;
    pressure: number;
}

export interface UnifiedFormatTemperature {
    startPeriodDate: string;
    measurementType: SensorType.temperature;
    temperature: number;
}

export type UnifiedMeasurementType =
    | UnifiedFormatElectricity
    | UnifiedFormatWater
    | UnifiedFormatHeat
    | UnifiedFormatSecurity
    | UnifiedFormatFireDetection
    | UnifiedFormatLeakDetection
    | UnifiedFormatNone
    | UnifiedFormatGas
    | UnifiedFormatTemperature
    | UnifiedFormatPressure;
