import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from '../types/types';
import Sidebar from '../common/components/Sidebar/Sidebar';
import Header from '../common/components/Header';
import { spacing } from '@mui/system';
import { CssBaseline, Hidden, Paper as MuiPaper } from '@mui/material';
import ChatModalComponent from '../common/components/chatModalComponent';
import { SnackbarComponent } from '../common/components/Snackbars';
import LogItemsModalComponent from '../common/components/logItemsModalComponent';
import { Outlet } from 'react-router-dom';
import { useStores } from '../hooks';
import Websockets from '../common/components/Websockets';

const drawerWidth = 240;

const DrawerCustom = styled.div`
    ${(props) => props.theme.breakpoints.up('md')} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }

    border-radius: 0 10px 10px 0 !important;
`;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background: ${(props) => props.theme.body.background};
    }


    .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
    }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};
    box-shadow: none;
    position: relative;
    width: calc(100vw - ${drawerWidth}px - 20px);

    @media (max-width: 600px) {
        width: 100vw;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

type DashboardPropsType = {
    //route: any;
    //width: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

const LayoutWithMenu: React.FC<DashboardPropsType> = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const { generalStore } = useStores();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        generalStore.loadOrganizationBrunches();
    }, []);

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <DrawerCustom>
                <Hidden implementation='js'>
                    <Sidebar
                        PaperProps={{
                            style: {
                                width: drawerWidth,
                                borderRadius: `0 10px 10px 0`,
                            },
                        }}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
                <Hidden smDown implementation='css'>
                    <Sidebar
                        PaperProps={{
                            style: {
                                width: drawerWidth,
                                borderRadius: `0 10px 10px 0`,
                            },
                        }}
                    />
                </Hidden>
            </DrawerCustom>
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                <LogItemsModalComponent />
                <ChatModalComponent />
                <SnackbarComponent />
                <Websockets />
                <MainContent p={5}>
                    <React.Suspense fallback={<>...</>}>
                        <Outlet />
                    </React.Suspense>
                </MainContent>
                {/*<Footer />*/}
            </AppContent>
            {/*<Settings />*/}
        </Root>
    );
};

export default LayoutWithMenu;
