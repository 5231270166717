import React, { ExoticComponent, lazy } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { RouteInfoType, RouteType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import { AccountRole } from '../../services/accounts/types';

const HubConfigPage = lazy(
    () => import('../maintainer/pages/HubConfigs/index'),
);
const UsersPage = lazy(() => import('./pages/list/AccountsList'));
const SystemObjectsPage = lazy(
    () => import('../maintainer/pages/systemObjectsPage/systemObjectsPage'),
) as unknown as ExoticComponent;
const AskueProvidersPage = lazy(
    () => import('../maintainer/pages/AskueProviders/index'),
);

const usersRoutes = {
    id: 'Сотрудники',
    name: 'Доступы',
    path: '/settings',
    hide: true,
    icon: <FontAwesomeIcon icon={faUsers} size={'lg'} />,
    roles: [AccountRole.Maintainer, AccountRole.Admin, AccountRole.Manager],
    component: <LayoutWithMenu />,
    routes: [
        {
            path: '/settings/',
            redirect: '/accounts/all',
            exact: true,
            hide: true,
        },
        {
            header: 'Настройки',
            type: RouteType.section,
            path: '/settings/fkdfmnjknr3984',
        },
        {
            path: '/settings/systemObjects',
            icon: <SidebarIcon type={SidebarIconType.systemObjects} />,
            name: 'Объекты учета',
            component: <SystemObjectsPage />,
            type: RouteType.route,
            roles: [AccountRole.Maintainer, AccountRole.Admin],
        },
        {
            path: '/settings/hubs',
            name: 'Настройка хабов',
            component: <HubConfigPage />,
            icon: <SidebarIcon type={SidebarIconType.hubSettings} />,
            type: RouteType.route,
            roles: [AccountRole.Admin],
        },
        {
            path: '/settings/all',
            name: 'Пользователи и доступы',
            component: <UsersPage />,
            icon: <SidebarIcon type={SidebarIconType.users} />,
            type: RouteType.route,
            roles: [AccountRole.Admin],
        },
        {
            path: '/settings/askueProviders',
            name: 'АСКУЭ Провайдеры',
            component: <AskueProvidersPage />,
            icon: <SidebarIcon type={SidebarIconType.pulse} />,
            type: RouteType.route,
            hide: true,
            roles: [AccountRole.Admin],
        },
    ],
} as RouteInfoType;
export default usersRoutes as RouteInfoType;
