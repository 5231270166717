import React from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useStores } from '../../../hooks';

const ConfirmModal = () => {
    const { authStore, generalStore } = useStores();
    const open = authStore.confirmModal;
    const close = () => {
        authStore.closeConfirmModal();
        authStore.closeAccountChangeModal();
    };

    const remove = () => {
        const accounts = localStorage.getItem('accounts');
        const filter = JSON.parse(accounts).filter(
            (i) => i.login !== authStore.currentConfirmData.login,
        );
        localStorage.setItem('accounts', JSON.stringify([...filter]));
        close();
        generalStore.showSnackbar(
            `Аккаунт ${authStore.currentConfirmData.login} удалён из быстрого доступа`,
        );
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={'xs'}
            fullWidth
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Подтверждение действия</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {`Вы уверены, что хотите удалить аккаунт ${authStore.currentConfirmData?.login} из быстрого
                    доступа?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} size={'small'}>
                    Отмена
                </Button>
                <Button
                    onClick={remove}
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(ConfirmModal);
