import React, { lazy } from 'react';
import { AccountRole } from '../../services/accounts/types';
import { RouteInfoType, RouteType } from '../../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import ConciergeLayout from '../../layouts/ConciergeLayout';

const ConciergePage = lazy(() => import('./pages/index'));

const conciergeRoutes = {
    id: 'Консьерж',
    name: 'Экран консьержа',
    path: '/concierge',
    hide: true,
    icon: <FontAwesomeIcon icon={faUsers} size={'lg'} />,
    roles: [AccountRole.Concierge],
    component: <ConciergeLayout />,
    routes: [
        {
            path: '/concierge/',
            redirect: '/concierge/main',
            exact: true,
            hide: true,
        },
        {
            path: '/concierge/main',
            name: 'Экран консьержа',
            component: <ConciergePage />,
            type: RouteType.route,
            roles: [AccountRole.Concierge],
        },
    ],
} as RouteInfoType;

export default conciergeRoutes as RouteInfoType;
