export enum ChatAPI {
    ChatsCreateChat = 'Chats.CreateChat',
    ChatsAddMessageToChat = 'Chats.AddMessageToChat',
    ChatsGetAccountChats = 'Chats.GetAccountChats',
    ChatsGetChatMessages = 'Chats.GetChatMessages',
    ChatsAddAccountToChat = 'Chats.AddAccountToChat',
    ChatsRemoveAccountFromChat = 'Chats.RemoveAccountFromChat',
    ChatsUpsertChatReadMark = 'Chats.UpsertChatReadMark',
    ChatsGetFlatChatListByHouse = 'Chats.GetFlatChatListByHouse',
    ChatsCreateFlatChat = 'Chats.CreateFlatChat',
    ChatsGetFlatChatList = 'Chats.GetFlatChatList',
    ChatsGetTechSupportChatList = 'Chats.GetTechSupportChatList',
    ChatsGetChat = 'Chats.GetChat',
}
