export enum OrganizationsRegistryAPI {
    getOrganizationsBrunches = 'OrganizationBranches.GetOrganizationBranches',
    createOrganizationBrunch = 'OrganizationBranches.CreateOrganizationBranch',
    updateOrganizationBrunch = 'OrganizationBranches.UpdateOrganizationBranch',
    OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesById = 'OrganizationBranches.GetOrgBranchSettingsAndAvailableModulesById',
    OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModules = 'OrganizationBranches.UpdateOrgBranchSettingsAndAvailableModules',
    OrganizationBranchesResetOrgBranchSettingsAndAvailableModules = 'OrganizationBranches.ResetOrgBranchSettingsAndAvailableModules',
    OrganizationBranchesGetManagementCompany = 'OrganizationBranches.GetManagementCompany',
    OrganizationBranchesUpdateManagementCompany = 'OrganizationBranches.UpdateManagementCompany',
    OrganizationBranchesRemoveWholeBranch = 'OrganizationBranches.RemoveWholeBranch',
}
