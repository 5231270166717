import React, { useContext, useEffect, useState } from 'react';
import heic2any from 'heic2any';
import Loader from '../Loader';
import FullScreenModalStoreContext from '../fullScreenModalComponent/store/context';

interface heicProps {
    url: string;
    attachments: any[];
    name: string;
    style: any;
    setLoad?: () => void;
}

const HeicConverter = (props: heicProps) => {
    const [imageUrl, setImageUrl] = useState<any>();
    const [loading, setLoading] = useState(false);
    const fullScreenModalStore = useContext(FullScreenModalStoreContext);
    const docFormats = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
    const filterAttachments =
        props.attachments.length &&
        props.attachments.filter((i) => {
            const filterSplit = i.fileName
                ? i.fileName.toLowerCase().split('.')
                : i.filename.toLowerCase().split('.');
            const filterName = filterSplit[filterSplit.length - 1];
            return !docFormats.includes(filterName);
        });

    useEffect(() => {
        if (!props.url) return;
        const fetchAndConvertImage = async () => {
            setLoading(true);
            try {
                const response = await fetch(props.url);
                const blob = await response.blob();
                const dataUrl = await heic2any({
                    blob,
                    toType: 'image/jpeg',
                });
                if (Array.isArray(dataUrl)) {
                    const imageUrls = dataUrl.map((blob) =>
                        URL.createObjectURL(blob),
                    );
                    setImageUrl(imageUrls);
                    setLoading(false);
                } else {
                    const imageUrl = URL.createObjectURL(dataUrl);
                    setImageUrl(imageUrl);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching or converting image:', error);
                setLoading(false);
            }
        };

        fetchAndConvertImage();
    }, [props.url]);

    return (
        <div>
            {loading ? (
                <div style={props.style}>
                    <Loader />
                </div>
            ) : (
                <img
                    style={props.style}
                    src={imageUrl}
                    onClick={(event) => {
                        event.stopPropagation();
                        fullScreenModalStore.openFullScreenModal(
                            props.url,
                            filterAttachments,
                            'image',
                            props.name,
                        );
                    }}
                    onLoad={props.setLoad}
                    alt='Converted to JPEG'
                />
            )}
        </div>
    );
};

export default HeicConverter;
