import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
    Alert,
    AlertStatus,
    AlertType,
    MeasurementType,
    SummaryAlert,
    SummaryAlertByCommonType,
    SummaryAlertByMeasurementType,
    SummaryHouse,
    SummaryOrgBranch,
} from '../services/alerts/models';
import {
    getAlerts,
    getAlertsByMeasurementSummary,
    getAlertsByTypeSummary,
    getAllAlerts,
    getHousesSummary,
    getOrgBranchSummary,
} from '../services/alerts';

interface IReportConfiguration {
    activatedFromDate: Date;
    activatedToDate: Date;
    statuses: AlertStatus[];
    systemObjectsIds: number[];
    types: string[];
}

interface ILoaders {
    orgBranchesSummary: boolean;
    housesSummary: boolean;
    alertTypesSummary: boolean;
    measurementTypeSummary: boolean;
    allAlertsLoading: boolean;
}

class AlertsStore {
    configuration: IReportConfiguration = {
        activatedFromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 365),
        activatedToDate: new Date(),
        statuses: [AlertStatus.active],
        systemObjectsIds: [],
        types: [],
    };
    alerts: { [s: string]: Alert[] } = {};
    alertsTotal = 0;
    isLoading = false;
    isLoaded = false;
    appliedFilters: {
        orgBranchId: string;
        houseId: string;
        measurementType: MeasurementType | 'All';
        commonType: AlertType | 'All';
    } = {
        orgBranchId: 'All',
        houseId: 'All',
        measurementType: 'All',
        commonType: 'All',
    };

    allAlerts: SummaryAlert[] = [];
    totalPages = {
        totalAlerts: 0,
        totalHouses: 0,
        totalMeasurements: 0,
        totalTypes: 0,
        totalOrgBranches: 0,
    };

    currentPage = {
        orgBranch: 0,
        house: 0,
        measurementType: 0,
        commonType: 0,
        alerts: 0,
    };
    readonly rowsPerPage = 10;

    summary: {
        housesSummary: SummaryHouse[];
        commonTypeSummary: SummaryAlertByCommonType[];
        measurementTypeSummary: SummaryAlertByMeasurementType[];
        orgBranchSummary: SummaryOrgBranch[];
    } = {
        housesSummary: [],
        commonTypeSummary: [],
        measurementTypeSummary: [],
        orgBranchSummary: [],
    };

    loading: ILoaders = {
        housesSummary: false,
        orgBranchesSummary: false,
        alertTypesSummary: false,
        measurementTypeSummary: false,
        allAlertsLoading: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setFrom(from: Date) {
        this.configuration.activatedFromDate = from;
        this.loadAlerts();
    }

    @action
    setTo(to: Date) {
        this.configuration.activatedToDate = to;
        this.loadAlerts();
    }

    @action
    setSystemObjects(systemObjectIds: number[]) {
        this.configuration.systemObjectsIds = systemObjectIds;
        this.loadAlerts();
    }

    @action
    setAlertStatuses(statuses: AlertStatus[]) {
        this.configuration.statuses = statuses;
        this.loadAlerts();
    }

    @action
    setAlertTypes(types: string[]) {
        this.configuration.types = types;
        this.loadAlerts();
    }

    @action
    setAlerts(alerts: Alert[]) {
        this.alerts = alerts.reduce((memo, item) => {
            memo[item.type] = memo[item.type] || [];
            memo[item.type].push({
                ...item,
                activatedAt: new Date(item.activatedAt),
                clearedAt: item.clearedAt ? new Date(item.clearedAt) : null,
                updatedAt: item.updatedAt ? new Date(item.updatedAt) : null,
            });
            return memo;
        }, {});
        this.alertsTotal = alerts.length;
    }

    async loadAlerts() {
        const configuration = this.configuration;
        if (
            !configuration.activatedFromDate ||
            !configuration.activatedToDate
        ) {
            return;
        }

        runInAction(() => {
            this.isLoading = true;
        });

        const result = await getAlerts(configuration);
        if (!result || !result.alerts) {
            runInAction(() => {
                this.isLoading = false;
            });
            return;
        }
        this.setAlerts(result.alerts);
        runInAction(() => {
            this.isLoading = false;
            this.isLoaded = true;
        });
    }

    ensureIsLoaded() {
        if (!this.isLoaded) this.loadAlerts();
    }

    @computed
    getAlertsBySystemObjectId(id: number) {
        /*const children = rootStore.systemObjectsStore.getChildrenBySystemObjectId(id);
        let childrenMap = children.reduce((memo, item) => {
            memo[item] = true;
            return memo;
        }, {})*/
        return Object.entries(this.alerts).reduce((memo, [type, alerts]) => {
            memo[type] = memo[type] || [];
            memo[type] = alerts.filter(
                (item) => item.systemObjectIdRootPath[0] === id,
            );
            return memo;
        }, {});
    }

    async getOrgBranchSummary(
        alertTypes: AlertType[],
        measurementTypes: MeasurementType[],
        skip: number,
        limit: number,
    ) {
        const res = await getOrgBranchSummary({
            alertTypes,
            measurementTypes,
            skip,
            limit,
        });
        runInAction(() => {
            this.summary = {
                ...this.summary,
                orgBranchSummary: res?.data.orgBranchInfos || [],
            };
            this.totalPages = {
                ...this.totalPages,
                totalOrgBranches: res?.data.total || 0,
            };
        });
    }

    async getHousesSummary(
        alertTypes: AlertType[],
        measurementTypes: MeasurementType[],
        orgBranchIds: number[],
        skip: number,
        limit: number,
    ) {
        runInAction(() => {
            this.loading.housesSummary = true;
        });
        const res = await getHousesSummary({
            alertTypes,
            measurementTypes,
            orgBranchIds,
            skip,
            limit,
        });
        runInAction(() => {
            this.summary = {
                ...this.summary,
                housesSummary: res?.data.houseInfos || [],
            };
            this.totalPages = {
                ...this.totalPages,
                totalHouses: res?.data.total || 0,
            };
            this.loading.housesSummary = false;
        });
    }

    async getAlertsByMeasurementSummary(
        alertTypes: AlertType[],
        houseIds: number[],
        measurementTypes: MeasurementType[],
        orgBranchIds: number[],
        skip: number,
        limit: number,
    ) {
        runInAction(() => {
            this.loading.measurementTypeSummary = true;
        });
        const res = await getAlertsByMeasurementSummary({
            houseIds,
            measurementTypes,
            orgBranchIds,
            alertTypes,
            skip,
            limit,
        });
        runInAction(() => {
            this.summary = {
                ...this.summary,
                measurementTypeSummary: res?.data.alertInfos || [],
            };
            this.totalPages = {
                ...this.totalPages,
                totalMeasurements: res?.data.total || 0,
            };
            this.loading.measurementTypeSummary = false;
        });
    }

    async getAlertsByTypeSummary(
        alertTypes: AlertType[],
        houseIds: number[],
        measurementTypes: MeasurementType[],
        orgBranchIds: number[],
        skip: number,
        limit: number,
    ) {
        runInAction(() => {
            this.loading.alertTypesSummary = true;
        });
        const res = await getAlertsByTypeSummary({
            alertTypes,
            houseIds,
            orgBranchIds,
            skip,
            measurementTypes,
            limit,
        });
        runInAction(() => {
            this.summary = {
                ...this.summary,
                commonTypeSummary: res?.data.alertInfos || [],
            };
            this.totalPages = {
                ...this.totalPages,
                totalTypes: res?.data.total || 0,
            };
            this.loading.alertTypesSummary = false;
        });
    }

    getFilters() {
        const { commonType, houseId, orgBranchId, measurementType } =
            this.appliedFilters;

        const houseFilter = houseId !== 'All' ? [+houseId] : [];
        const measTypeFilter =
            measurementType !== 'All' ? [measurementType] : [];
        const orgBranchFilter = orgBranchId !== 'All' ? [+orgBranchId] : [];
        const commonTypeFilter = commonType !== 'All' ? [commonType] : [];
        return {
            houseFilter,
            measTypeFilter,
            orgBranchFilter,
            commonTypeFilter,
        };
    }

    updateWithFilters(
        filters: AlertsStore['appliedFilters'],
        housesSkip: number,
        housesLimit: number,
        orgBranchesSkip: number,
        orgBranchesLimit: number,
        measurementsSkip: number,
        measurementsLimit: number,
        typesSkip: number,
        typesLimit: number,
        alertsSkip: number,
        alertsLimit: number,
    ) {
        this.appliedFilters = filters;
        const {
            houseFilter,
            measTypeFilter,
            orgBranchFilter,
            commonTypeFilter,
        } = this.getFilters();

        this.getAlertsByMeasurementSummary(
            commonTypeFilter,
            houseFilter,
            measTypeFilter,
            orgBranchFilter,
            measurementsSkip,
            measurementsLimit,
        );
        this.getAlertsByTypeSummary(
            commonTypeFilter,
            houseFilter,
            measTypeFilter,
            orgBranchFilter,
            typesSkip,
            typesLimit,
        );
        this.getHousesSummary(
            commonTypeFilter,
            measTypeFilter,
            orgBranchFilter,
            housesSkip,
            housesLimit,
        );
        this.getOrgBranchSummary(
            commonTypeFilter,
            measTypeFilter,
            orgBranchesSkip,
            orgBranchesLimit,
        );
        this.getAllAlerts(alertsSkip, alertsLimit);
    }

    async getAllAlerts(skip: number, limit: number) {
        const {
            houseFilter,
            measTypeFilter,
            orgBranchFilter,
            commonTypeFilter,
        } = this.getFilters();

        runInAction(() => {
            this.loading.allAlertsLoading = true;
        });
        const res = await getAllAlerts({
            alertTypes: commonTypeFilter,
            houseIds: houseFilter,
            measurementTypes: measTypeFilter,
            orgBranchIds: orgBranchFilter,
            flatIds: [],
            statuses: this.configuration.statuses,
            skip,
            limit,
        });
        runInAction(() => {
            this.currentPage = {
                ...this.currentPage,
                alerts: skip / this.rowsPerPage,
            };
            this.allAlerts = res?.data.alerts || [];
            this.totalPages = {
                ...this.totalPages,
                totalAlerts: res?.data.total || 0,
            };
            this.loading.allAlertsLoading = false;
        });
    }

    changePage(newPage: number, table: keyof AlertsStore['currentPage']) {
        const {
            measTypeFilter,
            orgBranchFilter,
            commonTypeFilter,
            houseFilter,
        } = this.getFilters();
        switch (table) {
            case 'house':
                this.getHousesSummary(
                    commonTypeFilter,
                    measTypeFilter,
                    orgBranchFilter,
                    newPage * this.rowsPerPage,
                    this.rowsPerPage,
                );
                this.currentPage = { ...this.currentPage, [table]: newPage };
                break;
            case 'orgBranch':
                this.getOrgBranchSummary(
                    commonTypeFilter,
                    measTypeFilter,
                    newPage * this.rowsPerPage,
                    this.rowsPerPage,
                );
                this.currentPage = { ...this.currentPage, [table]: newPage };
                break;
            case 'measurementType':
                this.getAlertsByMeasurementSummary(
                    commonTypeFilter,
                    houseFilter,
                    measTypeFilter,
                    orgBranchFilter,
                    newPage * this.rowsPerPage,
                    this.rowsPerPage,
                );
                this.currentPage = { ...this.currentPage, [table]: newPage };
                break;
            case 'commonType':
                this.getAlertsByTypeSummary(
                    commonTypeFilter,
                    houseFilter,
                    measTypeFilter,
                    orgBranchFilter,
                    newPage * this.rowsPerPage,
                    this.rowsPerPage,
                );
                this.currentPage = { ...this.currentPage, [table]: newPage };
                break;
        }
    }

    init() {
        this.updateWithFilters(
            {
                orgBranchId: 'All',
                houseId: 'All',
                measurementType: 'All',
                commonType: 'All',
            } as AlertsStore['appliedFilters'],
            0,
            this.rowsPerPage,
            0,
            this.rowsPerPage,
            0,
            this.rowsPerPage,
            0,
            this.rowsPerPage,
            0,
            this.rowsPerPage,
        );
        this.loadAlerts();
    }
}

export default AlertsStore;
