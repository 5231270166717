import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import ChatModalStoreContext from './store/context';
import { Dialog } from '@mui/material';
import ChatData from './chatData';

const ChatModalComponent = () => {
    const chatStore = useContext(ChatModalStoreContext);
    const isOpen = chatStore.isOpen;

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth={'sm'}>
            <ChatData chatHeight={'550px'} chatPlace={'modal'} />
        </Dialog>
    );
};

export default observer(ChatModalComponent);
