import { execute } from '../../apiCommandsExecutor';
import { ObjectStorageAPI } from './methods';
import {
    ObjectStorageGetDownloadLinkRequest,
    ObjectStorageGetDownloadLinkResponse,
    ObjectStorageGetUploadLinkRequest,
    ObjectStorageGetUploadLinkResponse,
} from './requestsResponses';

export const getUploadLink = async (
    params: ObjectStorageGetUploadLinkRequest,
) => {
    const res = await execute<
        ObjectStorageGetUploadLinkRequest,
        ObjectStorageGetUploadLinkResponse
    >(ObjectStorageAPI.ObjectStorageGetUploadLink, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getDownloadLink = async (
    params: ObjectStorageGetDownloadLinkRequest,
) => {
    const res = await execute<
        ObjectStorageGetDownloadLinkRequest,
        ObjectStorageGetDownloadLinkResponse
    >(ObjectStorageAPI.ObjectStorageGetDownloadLink, {
        ...params,
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
