import { execute, generateClientSyncId } from '../../apiCommandsExecutor';
import { OrganizationsRegistryAPI } from './methods';
import {
    CreateOrganizationBranchRequest,
    CreateOrganizationBranchResponse,
    GetOrganizationBranchesRequest,
    GetOrganizationBranchesResponse,
    OrganizationBranchesGetManagementCompanyRequest,
    OrganizationBranchesGetManagementCompanyResponse,
    OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesByIdRequest,
    OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesByIdResponse,
    OrganizationBranchesRemoveWholeBranchRequest,
    OrganizationBranchesRemoveWholeBranchResponse,
    OrganizationBranchesUpdateManagementCompanyRequest,
    OrganizationBranchesUpdateManagementCompanyResponse,
    OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesRequest,
    OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesResponse,
    UpdateOrganizationBranchRequest,
    UpdateOrganizationBranchResponse,
} from './requestsResponses';

export const getOrganizationBrunches = async () => {
    const res = await execute<
        GetOrganizationBranchesRequest,
        GetOrganizationBranchesResponse
    >(OrganizationsRegistryAPI.getOrganizationsBrunches, {
        clientSyncId: generateClientSyncId(),
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const createOrganizationBrunch = async (
    params: CreateOrganizationBranchRequest,
) => {
    const res = await execute<
        CreateOrganizationBranchRequest,
        CreateOrganizationBranchResponse
    >(OrganizationsRegistryAPI.createOrganizationBrunch, {
        clientSyncId: generateClientSyncId(),
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateOrganizationBrunch = async (
    params: UpdateOrganizationBranchRequest,
) => {
    const res = await execute<
        UpdateOrganizationBranchRequest,
        UpdateOrganizationBranchResponse
    >(OrganizationsRegistryAPI.updateOrganizationBrunch, {
        clientSyncId: generateClientSyncId(),
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getOrgBranchSettingsAndAvailableModulesById = async (
    params: OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesByIdRequest,
) => {
    const res = await execute<
        OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesByIdRequest,
        OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesByIdResponse
    >(
        OrganizationsRegistryAPI.OrganizationBranchesGetOrgBranchSettingsAndAvailableModulesById,
        { ...params },
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateOrgBranchSettingsAndAvailableModules = async (
    params: OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesRequest,
) => {
    const res = await execute<
        OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesRequest,
        OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesResponse
    >(
        OrganizationsRegistryAPI.OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModules,
        { ...params },
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getManagementCompany = async (
    params: OrganizationBranchesGetManagementCompanyRequest,
) => {
    const res = await execute<
        OrganizationBranchesGetManagementCompanyRequest,
        OrganizationBranchesGetManagementCompanyResponse
    >(OrganizationsRegistryAPI.OrganizationBranchesGetManagementCompany, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateManagementCompany = async (
    params: OrganizationBranchesUpdateManagementCompanyRequest,
) => {
    const res = await execute<
        OrganizationBranchesUpdateManagementCompanyRequest,
        OrganizationBranchesUpdateManagementCompanyResponse
    >(OrganizationsRegistryAPI.OrganizationBranchesUpdateManagementCompany, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const removeOrgBranch = async (
    params: OrganizationBranchesRemoveWholeBranchRequest,
) => {
    const res = await execute<
        OrganizationBranchesRemoveWholeBranchRequest,
        OrganizationBranchesRemoveWholeBranchResponse
    >(OrganizationsRegistryAPI.OrganizationBranchesRemoveWholeBranch, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
