import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { LogItem } from '../../../services/logs/models';
import moment from 'moment';
import archiveIcon from './assets/archiveIcon.png';

interface LogItemForHouseProps {
    log: LogItem;
}

const LogItemForHouse = (props: LogItemForHouseProps) => {
    const [logInfo, setLogInfo] = useState(false);
    const sensorMeasurementType = () => {
        let backColor;
        let text;

        if (props.log.sensorMeasurementKindId === 'HotWater') {
            backColor = '#ff6868';
            text = 'ГВС';
        }
        if (props.log.sensorMeasurementKindId === 'ColdWater') {
            backColor = '#68c9ff';
            text = 'ХВС';
        }
        if (props.log.sensorMeasurementKindId === 'Heat') {
            backColor = '#ff993b';
            text = 'Тепло';
        }
        if (props.log.sensorMeasurementKindId === 'ElectricEnergy') {
            backColor = '#0A997F';
            text = 'Э/Э';
        }
        return (
            <span
                style={{
                    fontFamily: 'Formular',
                    marginLeft: 10,
                    fontSize: 14,
                    width: 70,
                    height: 20,
                    backgroundColor: backColor,
                    borderRadius: '12px',
                    padding: '0px 2px',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {text}
            </span>
        );
    };

    const iButton = () => {
        if (!logInfo) {
            return (
                <span
                    onClick={() => setLogInfo(!logInfo)}
                    style={{
                        color: '#2A73FF',
                        padding: '2px 5px',
                        cursor: 'pointer',
                    }}
                >
                    Показать технические детали
                </span>
            );
        } else {
            return (
                <span
                    onClick={() => setLogInfo(!logInfo)}
                    style={{
                        color: '#2A73FF',
                        padding: '2px 5px',
                        cursor: 'pointer',
                    }}
                >
                    Скрыть технические детали
                </span>
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: props.log.success ? '#61c6491a' : '#ff4f371a',
                marginTop: 10,
                padding: 5,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 5,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                        style={{
                            fontFamily: 'Formular',
                            fontSize: 14,
                            color: '#959595',
                        }}
                    >
                        {moment(props.log.addedAt).format('DD.MM.YYYY HH:mm')}
                    </span>
                    <span
                        style={{
                            fontFamily: 'Formular',
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                    >
                        {props.log.flatName || 'Номер квартиры'}
                    </span>
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {props.log.measurementSourceId !== 'CurrentValues' && (
                        <span
                            style={{
                                fontFamily: 'Formular',
                                marginLeft: 10,
                                fontSize: 14,
                                width: 100,
                                height: 20,
                                justifyContent: 'center',
                                backgroundColor: '#06325aad',
                                borderRadius: '12px',
                                padding: '0px 2px',
                                color: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{ marginRight: 5 }}
                                src={archiveIcon}
                                width={14}
                                height={14}
                            />
                            Архивы
                        </span>
                    )}
                    {sensorMeasurementType()}
                </div>
            </div>
            {!props.log.success ? (
                <span style={{ padding: '0 5px' }}>
                    {props.log.displayText.length > 0
                        ? props.log.displayText
                        : 'Неизвестная ошибка'}
                </span>
            ) : (
                <span style={{ padding: '0 5px' }}>
                    Операция успешно выполнена
                </span>
            )}
            {!props.log.success && iButton()}
            {logInfo ? (
                <div
                    style={{
                        borderLeft: '2px solid #D71600',
                        padding: '0 10px',
                        marginLeft: 20,
                        marginTop: 10,
                    }}
                >
                    <span>{props.log.text}</span>
                </div>
            ) : null}
        </div>
    );
};

export default observer(LogItemForHouse);
