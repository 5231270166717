import { TokenPair } from '../services/auth/models';
import { Organization } from '../services/organizations/models';

export const getToken = () => {
    const tokenObj = localStorage.getItem('token');

    if (tokenObj) {
        return (JSON.parse(tokenObj) as TokenPair).authToken;
    } else {
        return '';
    }
};

export const saveTokenPair = (token: TokenPair) => {
    if (!token) return;
    localStorage.setItem('token', JSON.stringify(token));
};

export const saveTokenAndOrganization = (
    token: TokenPair,
    organization: Organization,
    accountLogin: string,
) => {
    if (!token) return;
    if (!organization) return;
    if (localStorage.accounts) {
        if (localStorage.accounts.includes(accountLogin)) {
            const accounts = JSON.parse(localStorage.getItem('accounts'));
            const newAccs = accounts.map((i) =>
                i.login === accountLogin ? { ...i, token: token } : i,
            );
            localStorage.setItem('accounts', JSON.stringify([...newAccs]));
        } else {
            localStorage.setItem(
                'accounts',
                JSON.stringify([
                    ...JSON.parse(localStorage.accounts),
                    {
                        login: accountLogin,
                        token: token,
                        organization: organization,
                    },
                ]),
            );
        }
    } else {
        localStorage.setItem(
            'accounts',
            JSON.stringify([
                {
                    login: accountLogin,
                    token: token,
                    organization: organization,
                },
            ]),
        );
    }
};

export const removeTokenPair = () => {
    localStorage.removeItem('token');
};
