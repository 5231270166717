import {
    AccountsGetAccountsByFlatRequest,
    AccountsGetAccountsByFlatResponse,
    AddHouseResidentToFlatRequest,
    AddHouseResidentToFlatResponse,
    HouseResidentInvitationsCreateHouseResidentInvitationRequest,
    HouseResidentInvitationsCreateHouseResidentInvitationResponse,
    HouseResidentInvitationsGetHouseResidentByPhoneRequest,
    HouseResidentInvitationsGetHouseResidentByPhoneResponse,
} from './requestsResponses';
import { execute } from '../../apiCommandsExecutor';
import { FlatUsersAPI } from './methods';

export const addNewFlatUser = async (params: AddHouseResidentToFlatRequest) => {
    const res = await execute<
        AddHouseResidentToFlatRequest,
        AddHouseResidentToFlatResponse
    >(FlatUsersAPI.AccountsAddHouseResidentToFlat, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getUsersData = async (
    params: AccountsGetAccountsByFlatRequest,
) => {
    const res = await execute<
        AccountsGetAccountsByFlatRequest,
        AccountsGetAccountsByFlatResponse
    >(FlatUsersAPI.AccountsGetAccountsByFlat, {
        flatId: params.flatId,
        roles: params.roles,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getHouseResidentByPhone = async (
    params: HouseResidentInvitationsGetHouseResidentByPhoneRequest,
) => {
    const res = await execute<
        HouseResidentInvitationsGetHouseResidentByPhoneRequest,
        HouseResidentInvitationsGetHouseResidentByPhoneResponse
    >(FlatUsersAPI.HouseResidentInvitationsGetHouseResidentByPhone, {
        ...params,
    });
    return res.data;
};

export const createHouseResidentInvite = async (
    params: HouseResidentInvitationsCreateHouseResidentInvitationRequest,
) => {
    const res = await execute<
        HouseResidentInvitationsCreateHouseResidentInvitationRequest,
        HouseResidentInvitationsCreateHouseResidentInvitationResponse
    >(FlatUsersAPI.HouseResidentInvitationsCreateHouseResidentInvitation, {
        ...params,
    });
    return res.data;
};
