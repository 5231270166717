import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { getSupportChatList } from '../../../services/chat';
import { TechSupportChatLastMessageClient } from '../../../services/chat/models';

export class SupportChatListStore {
    searchCount: string = '';
    supportChatList: TechSupportChatLastMessageClient[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getSupportChatList(skip: number) {
        const response = await getSupportChatList({
            limit: 120,
            skip: skip,
        });
        runInAction(() => {
            this.supportChatList = response.flatChatLastMessages;
        });
    }

    @action
    setSearchCount(text: string) {
        this.searchCount = text;
    }

    @computed
    flatChatLastMessagesFilterByCount() {
        if (this.searchCount.length === 0) {
            return this.supportChatList;
        } else {
            return this.supportChatList.filter((i) =>
                i.chat.title.includes(this.searchCount),
            );
        }
    }
}

const supportChatListStore = new SupportChatListStore();

export default supportChatListStore;
