import { execute, generateClientSyncId } from '../../apiCommandsExecutor';
import { ConstantsRegistryAPI } from './methods';
import {
    GetAlertStatusesRequest,
    GetConnectionLineModelsRequest,
    GetConnectionLineModelsResponse,
    GetGetAlertStatusesResponse,
    GetGetAlertTypesRequest,
    GetGetAlertTypesResponse,
    GetHubModelsRequest,
    GetHubModelsResponse,
    GetSensorMeasurementKindsRequest,
    GetSensorMeasurementKindsResponse,
    GetSensorModelsRequest,
    GetSensorModelsResponse,
} from './requestsResponses';

export const getMeasurementKinds = async () => {
    const res = await execute<
        GetSensorMeasurementKindsRequest,
        GetSensorMeasurementKindsResponse
    >(ConstantsRegistryAPI.getSensorMeasurementKinds, {
        clientSyncId: generateClientSyncId(),
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSensorModels = async () => {
    const res = await execute<GetSensorModelsRequest, GetSensorModelsResponse>(
        ConstantsRegistryAPI.getSensorModels,
        {
            clientSyncId: generateClientSyncId(),
        },
    );
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getHubModels = async () => {
    const res = await execute<GetHubModelsRequest, GetHubModelsResponse>(
        ConstantsRegistryAPI.getHubModels,
        {
            clientSyncId: generateClientSyncId(),
        },
    );
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getConnectionLineModels = async () => {
    const res = await execute<
        GetConnectionLineModelsRequest,
        GetConnectionLineModelsResponse
    >(ConstantsRegistryAPI.getConnectionLineModels, {
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAlertStatuses = async () => {
    const res = await execute<
        GetAlertStatusesRequest,
        GetGetAlertStatusesResponse
    >(ConstantsRegistryAPI.getAlertStatuses, {
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getAlertTypes = async () => {
    const res = await execute<
        GetGetAlertTypesRequest,
        GetGetAlertTypesResponse
    >(ConstantsRegistryAPI.getAlertTypes, {
        clientSyncId: generateClientSyncId(),
    });
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
