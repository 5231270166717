export interface SensorAlerts {
    amountActivatedDuringPeriod: number | null;
    amountClearedDuringPeriod: number | null;
    totalAmountActivatedByPeriodEnd: number | null;
}

interface ObjectInfo {
    id: number;
    name: string;
}

interface Parent {
    flat: ObjectInfo | null;
    house: ObjectInfo;
    placeholder: ObjectInfo | null;
}

export type AlertType =
    | 'LostConnection'
    | 'OddConsumption'
    | 'SensorAlert'
    | 'SecurityAlert'
    | 'ConnectionLineNotFound'
    | 'ConnectionLineNotWorking'
    | 'LeakageAlert'
    | 'SensorVerificationNeeded'
    | 'BadConfiguration'
    | 'MagneticField'
    | 'SensorOpening'
    | 'ClockError'
    | 'LowBattery'
    | 'UnsuccessfulSelfDiagnosis'
    | 'ValueOverLimit'
    | 'WrongSoftwareCRC'
    | 'OperatingTimeExceeded'
    | 'ErrorEEPROM'
    | 'FireAlert'
    | 'SmokeAlert';

export const Alerts: { [key in AlertType]?: string } = {
    BadConfiguration: 'Неправильная конфигурация',
    LostConnection: 'Потеря соединения',
    OddConsumption: 'Неравномерное потербление',
    SensorAlert: 'Сработало оповещение',
    SecurityAlert: 'Предупреждение системы безопасности',
    ConnectionLineNotFound: 'Линия не обнаружена',
    ConnectionLineNotWorking: 'Линия неисправна',
    LeakageAlert: 'Предупреждение о протечке',
    SensorVerificationNeeded: 'Требуется проверка датчика',
    MagneticField: 'Магнитное поле',
    SensorOpening: 'Вскрытие сенсора',
    ClockError: 'Неправильно установленные время или дата',
    LowBattery: 'Низкий заряд батареи',
    UnsuccessfulSelfDiagnosis: 'Неудачная самодиагностика',
    ValueOverLimit: 'Превышение лимита значения',
    WrongSoftwareCRC: 'Неверный программный CRC',
    OperatingTimeExceeded: 'Превышено время работы',
    ErrorEEPROM: 'Ошибка ЭСППЗУ',
    FireAlert: 'Оповещение пожарной сигнализации',
    SmokeAlert: 'Оповещение датчика дыма',
};

interface AlertDetails {
    type: AlertType;
    alertMessage: string;
}

export interface ActiveAlert extends AlertModel {
    acknowledgedByAccountId: number | null;
    activatedAt: Date | null;
    clearedAt: Date | null;
    details: AlertDetails | null;
    orgBranchId: number;
    organizationId: number;
    status: string;
    type: string;
    updatedAt: Date | null;
}

export interface AlertModel {
    acknowledgedByAccountId: number | null;
    activatedAt: Date | null;
    clearedAt: Date | null;
    details: AlertDetails | null;
    orgBranchId: number;
    organizationId: number;
    status: string;
    type: string;
    updatedAt: Date | null;
}

export interface SummaryAlert extends AlertModel {
    acknowledgedByAccountId: number | null;
    activatedAt: Date | null;
    clearedAt: Date | null;
    details: AlertDetails | null;
    orgBranchId: number;
    organizationId: number;
    parents: Parent;
    status: string;
    systemObject: ObjectInfo;
    type: AlertType;
    updatedAt: Date | null;
    sensorAddress: string | null;
    sensorSerialNumber: string | null;
}

export interface Alert extends AlertModel {
    acknowledgedByAccountId: number | null;
    activatedAt: Date | null;
    clearedAt: Date | null;
    details: AlertDetails | null;
    orgBranchId: number;
    organizationId: number;
    status: string;
    systemObjectId: number;
    systemObjectIdRootPath: number[];
    type: string;
    updatedAt: Date | null;
}

export enum AlertStatus {
    active = 'Active',
    cleared = 'Cleared',
}

export const Measurements: { [key in MeasurementType]?: string } = {
    Heat: 'Тепло',
    ElectricEnergy: 'Электроэнергия',
    ColdWater: 'ХВС',
    HotWater: 'ГВС',
    CleanWater: 'Питьевая вода',
    Gas: 'Газ',
    Security: 'Безопасность',
    RelayController: 'Контроллер электричества',
    LeakDetection: 'Контроль протечки',
    FireDetection: 'Пожарная сигнализация',
    Temperature: 'Температура',
    Pressure: 'Давление',
};

export type MeasurementType =
    | 'Heat'
    | 'ElectricEnergy'
    | 'ColdWater'
    | 'HotWater'
    | 'CleanWater'
    | 'Gas'
    | 'Security'
    | 'LeakDetection'
    | 'FireDetection'
    | 'RelayController'
    | 'Temperature'
    | 'Pressure'
    | 'NONE';

export const RubezhFireZoneAlert: {
    [key in RubezhFireZoneAlertType]?: string;
} = {
    notProtected: 'Норма',
    failure: 'Неисправность',
    badConfiguration: 'Не совпадают конфигурации прибора и МС-КП',
    fire1: 'Внимание',
    fire2: 'Пожар',
    lostConnection: 'Потеря связи',
    warning: 'Внимание',
    disabled: 'Отключено («Обход»)',
};

export type RubezhFireZoneAlertType =
    | 'notProtected'
    | 'failure'
    | 'badConfiguration'
    | 'fire1'
    | 'fire2'
    | 'lostConnection'
    | 'warning'
    | 'disabled';

export interface SummaryData {
    amountConnectionLines: number;
    amountFlats: number;
    amountHouses: number;
    amountHubs: number;
    amountOrgBranches: number;
    amountSensors: number;
}

export interface SummaryOrgBranch {
    amountAlerts: number;
    amountFlats: number;
    amountHouses: number;
    flatsWithAlerts: number;
    housesWithAlerts: number;
    name: string;
}

export interface SummaryHouse {
    amountAlerts: number;
    amountFlats: number;
    flatsWithAlerts: number;
    name: string;
}

export interface SummaryAlertByCommonType {
    amountCommonAlerts: number;
    commonType: string;
}

export interface SummaryAlertByMeasurementType {
    amountMeasurementAlerts: number;
    measurementType: MeasurementType;
}

export interface SummaryAlertInfo {
    alerts: SummaryAlert[];
    total: number;
}

export interface SummaryOrgBranchInfo {
    orgBranchInfos: SummaryOrgBranch[];
    total: number;
}

export interface SummaryHouseInfo {
    houseInfos: SummaryHouse[];
    total: number;
}

export interface SummaryAlertByCommonTypeInfo {
    alertInfos: SummaryAlertByCommonType[];
    total: number;
}

export interface SummaryAlertByMeasurementTypeInfo {
    alertInfos: SummaryAlertByMeasurementType[];
    total: number;
}
