import { adaptV4Theme, createTheme } from '@mui/material';

import variants, { VariantType } from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows from './shadows';
import props from './props';
import overrides from './overrides';

const theme = (variant: VariantType) => {
    return createTheme(
        adaptV4Theme(
            {
                spacing: 4,
                breakpoints: breakpoints,
                overrides: overrides,
                props: props,
                typography: typography,
                shadows: shadows,
                palette: variant.palette,
                //@ts-ignore
                name: variant.name,
                body: variant.body,
                header: variant.header,
                sidebar: variant.sidebar,
            },
            /*{
                name: variant.name,
                body: variant.body,
                header: variant.header,
                sidebar: variant.sidebar,
            },*/
        ),
    );
};

const themes = variants.map((variant) => theme(variant));
export default themes;
