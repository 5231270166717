import { action, makeAutoObservable } from 'mobx';
import {
    addMessageToChat,
    getChatMessages,
    readChatMessages,
} from '../../../../services/chat';
import {
    ChatMessageAttachment,
    ChatMessageClient,
} from '../../../../services/chat/models';
import moment from 'moment/moment';

export class ChatStore {
    isOpen = false;
    chatId: number;
    chatTitle: string;
    chatMessages: ChatMessageClient[] = [];
    attachments: ChatMessageAttachment[] = [];
    links: { link: string; fileName: string; fileId: string }[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getChatMessages(chatId: number) {
        const response = await getChatMessages({
            limit: 100,
            skip: 0,
            chatId: chatId,
        });
        this.setMessages(response.messages.reverse());
    }

    async updateChatMessages(chatId: number) {
        const response = await getChatMessages({
            limit: 100,
            skip: 0,
            chatId: chatId,
        });
        if (this.chatMessages.length > 0) {
            if (
                this.chatMessages[this.chatMessages.length - 1].createdAt ===
                response.messages[0].createdAt
            ) {
                return;
            } else {
                this.setMessages(response.messages.reverse());
            }
        } else this.setMessages(response.messages.reverse());
    }

    async addMessageToChat(content: string) {
        const response = await addMessageToChat({
            chatId: this.chatId,
            content: content,
            attachments: this.attachments,
        });
        return response === null;
    }

    async readChatMessages() {
        const response = await readChatMessages({
            chatId: this.chatId,
            readingTime: moment().toDate(),
        });
        return response === null;
    }

    @action
    openModal(chatId: number, title: string) {
        this.isOpen = true;
        this.setChat(chatId, title);
    }

    @action
    closeModal() {
        this.setChat(undefined, undefined);
        this.chatMessages = [];
        this.attachments = [];
        this.links = [];
        this.isOpen = false;
    }

    @action
    setChat(chatId: number, chatTitle: string) {
        this.chatId = chatId;
        this.chatTitle = chatTitle;
    }

    @action
    setMessages(messages: ChatMessageClient[]) {
        this.chatMessages = messages;
    }

    @action
    setAttachments(attachments: ChatMessageAttachment[]) {
        this.attachments = attachments;
    }

    @action
    setLinks(links) {
        this.links = links;
    }

    @action
    removeAttachment(name?: string) {
        if (!name) {
            return;
        }
        this.attachments = this.attachments.filter(
            (attachment) => attachment.name !== name,
        );
    }

    @action
    removeLink(name?: string) {
        if (!name) {
            return;
        }
        this.links = this.links.filter((link) => link.fileName !== name);
    }
}

const chatStore = new ChatStore();

export default chatStore;
