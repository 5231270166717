import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { AccountRole } from '../../services/accounts/types';
import ConciergeLayout from '../../layouts/ConciergeLayout';
import { RouteInfoType, RouteType } from '../../types/types';
import React, { lazy } from 'react';

const SmartDevicesRegistryPage = lazy(() => import('./pages/index'));
const TechSupportAccountsPage = lazy(
    () => import('./pages/techSupportAccounts'),
);

const superRoutes = {
    id: 'SuperUser',
    name: 'SuperUser',
    path: '/super',
    hide: true,
    icon: <FontAwesomeIcon icon={faUsers} size={'lg'} />,
    roles: [AccountRole.SuperUser],
    component: <ConciergeLayout />,
    routes: [
        {
            path: '/super/',
            redirect: '/super/smart-devices',
            exact: true,
            hide: true,
        },
        {
            path: '/super/smart-devices',
            name: 'Реестр умных устройств',
            component: <SmartDevicesRegistryPage />,
            type: RouteType.route,
            roles: [AccountRole.SuperUser],
        },
        {
            path: '/super/tech-support-accounts',
            name: 'Доступы технической поддержки',
            component: <TechSupportAccountsPage />,
            type: RouteType.route,
            roles: [AccountRole.SuperUser],
        },
    ],
} as RouteInfoType;

export default superRoutes as RouteInfoType;
