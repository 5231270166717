import axios, { AxiosResponse } from 'axios';
import { getToken } from './token';

export const API_URL = ''; //"https://dev-wise-city.anybots.ru";//"https://dev-wise-city.anybots.ru";
export const API_URL_EXECUTE_ENDPOINT = '/api/execute';

export const execute = async <RequestType, ResponseType>(
    command: string,
    payload: RequestType,
) => {
    const result: AxiosResponse<ResponseType> = await axios.post(
        `${API_URL_EXECUTE_ENDPOINT}/${command}`,
        {
            ...payload,
            clientSyncId: generateClientSyncId(),
            method: command,
        },
        {
            headers: {
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
            validateStatus: () => true,
        },
    );
    return result;
};

export const generateClientSyncId = () => Math.random().toString();
