import {
    LogsGetSensorPollingLogsRequest,
    LogsGetSensorPollingLogsResponse,
    LogsGetSensorsPollingLogsForHouseRequest,
    LogsGetSensorsPollingLogsForHouseResponse,
} from './requestsResponses';
import { execute, generateClientSyncId } from '../../apiCommandsExecutor';
import { LogsAPI } from './methods';

export const getLogs = async (params: LogsGetSensorPollingLogsRequest) => {
    const res = await execute<
        LogsGetSensorPollingLogsRequest,
        LogsGetSensorPollingLogsResponse
    >(LogsAPI.getSensorPollingLogs, {
        clientSyncId: generateClientSyncId(),
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getLogsForHouse = async (
    params: LogsGetSensorsPollingLogsForHouseRequest,
) => {
    const res = await execute<
        LogsGetSensorsPollingLogsForHouseRequest,
        LogsGetSensorsPollingLogsForHouseResponse
    >(LogsAPI.getSensorsPollingLogsForHouse, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
